import React, { useState } from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"
import { useStaticQuery, graphql } from "gatsby"
import { rgba } from "polished"

import {
  H6,
  Sidebar,
  Box,
  Button,
  HouseCard,
  SimpleSelect,
  Flex,
  H1,
  H2,
  RichText,
  Wrapper,
} from "components"

const timeframeRanking = {
  Today: 0,
  "30-60 Days": 1,
  "60-90 Days": 2,
  "To be determined": 3,
}

const bedroomFilterTypes = [
  { value: 1, label: "1 Bedroom" },
  { value: 2, label: "2 Bedrooms" },
  { value: 3, label: "3 Bedrooms" },
  { value: 4, label: "4 Bedrooms" },
]

const priceFilterTypes = [
  { value: "0-250000", label: "Less than $250,000" },
  { value: "250000-300000", label: "$250,000-$300,000" },
  { value: "300000-350000", label: "$300,000-$350,000" },
  { value: "350000-400000", label: "$350,000-$400,000" },
  { value: "400000-450000", label: "$400,000-$450,000" },
  { value: "450000-500000", label: "$450,000-$500,000" },
  { value: "500000-100000000", label: "More than $500,000" },
]

const dateFilterTypes = [
  { value: "Today", label: "Today" },
  { value: "30-60 Days", label: "30-60 Days" },
  { value: "60-90 Days", label: "60-90 Days" },
]

const Option = ({ label, onClick, active, ...rest }) => (
  <H2
    as="p"
    fontSize={4}
    css={css({
      "&:not(:last-of-type)": { mb: 3 },
    })}
    {...rest}
  >
    <span
      children={label}
      className={active ? "active" : ""}
      css={css({
        textDecoration: "none",
        color: "inherit",
        backgroundImage: theme =>
          `linear-gradient(to bottom, ${rgba(
            theme.colors.text,
            0.25
          )} 50%, ${rgba(theme.colors.text, 0.25)} 50%)`,
        backgroundRepeat: "repeat-x",
        backgroundSize: "1px 1px",
        backgroundPosition: "0 100%",
        cursor: "pointer",
        "&.active": {
          fontWeight: 800,
          backgroundImage: theme =>
            `linear-gradient(to bottom, ${rgba(
              theme.colors.text,
              5
            )} 50%, ${rgba(theme.colors.text, 0.25)} 50%)`,
        },
      })}
      onClick={onClick}
    />
  </H2>
)

function HouseSelector({ subtext, full }) {
  const { houses } = useStaticQuery(
    graphql`
      {
        houses: allPrismicMoveInReadyHome {
          nodes {
            prismicId
            uid
            data {
              sticker {
                url
                alt
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid
                }
              }
              image {
                url
                alt
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
              name {
                text
              }
              home_category {
                document {
                  ... on PrismicHomeCategory {
                    data {
                      name
                    }
                  }
                }
              }
              move_in_date
              price
              floorplan
              sq_footage
              bathrooms
              bedrooms
              community {
                document {
                  ... on PrismicCommunity {
                    id
                    data {
                      location {
                        document {
                          ... on PrismicLocation {
                            data {
                              name
                            }
                          }
                        }
                      }
                      name {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  let locations = []
  let homeCategories = []

  houses.nodes.forEach(house => {
    // Set list of filterable locations based on communities
    const LOCATION =
      house?.data?.community?.document?.data?.location?.document?.data?.name ??
      null
    if (LOCATION && locations.indexOf(LOCATION) === -1) {
      locations.push(LOCATION)
    }

    // Set list of filterable home categories based on communities
    const HOME_CATEGORY =
      house?.data?.home_category?.document?.data?.name ?? null
    if (HOME_CATEGORY && homeCategories.indexOf(HOME_CATEGORY) === -1) {
      homeCategories.push(HOME_CATEGORY)
    }
  })

  const [priceFilter, setPriceFilter] = useState("")
  const [priceFilterBottom, setPriceFilterBottom] = useState("")
  const [priceFilterTop, setPriceFilterTop] = useState("")
  const [bedroomFilter, setBedroomFilter] = useState("")
  const [dateFilter, setDateFilter] = useState("")
  const [activeLocationFilters, setActiveLocationFilters] = useState([])
  const [locationOpen, setLocationOpen] = useState(false)
  const [typeOpen, setTypeOpen] = useState(false)
  const [activeTypeFilters, setActiveTypeFilters] = useState([])

  const resetFilters = () => {
    setPriceFilter("")
    setPriceFilterBottom("")
    setPriceFilterTop("")
    setBedroomFilter("")
    setDateFilter("")
  }

  const toggleLocation = location => {
    let filters = JSON.parse(JSON.stringify(activeLocationFilters))
    if (filters.indexOf(location) !== -1) {
      filters.splice(filters.indexOf(location), 1)
    } else {
      filters.push(location)
    }
    setActiveLocationFilters(filters)
  }

  const toggleType = type => {
    let filters = JSON.parse(JSON.stringify(activeTypeFilters))
    if (filters.indexOf(type) !== -1) {
      filters.splice(filters.indexOf(type), 1)
    } else {
      filters.push(type)
    }
    setActiveTypeFilters(filters)
  }

  return (
    <>
      <Wrapper as="section" id="options" pt={full ? [128, 192] : [5, 6]}>
        <H1>
          Find a{` `}
          <span
            css={css({
              position: "relative",
              fontWeight: "bold",
              boxShadow: "inset 0 -1px 0 0 currentColor",
              cursor: "pointer",
            })}
            onClick={() => {
              setTypeOpen(!typeOpen)
            }}
            onKeyUp={event => {
              if (event.keyCode === 13) {
                event.preventDefault()
                setTypeOpen(!typeOpen)
              }
            }}
            // tabIndex={1}
          >
            {activeTypeFilters.length === 0 ? (
              "Home"
            ) : activeTypeFilters.length === 1 ? (
              activeTypeFilters.toString()
            ) : (
              <span>
                Home<sup>{activeTypeFilters.length}</sup>
              </span>
            )}
            <svg
              width={14}
              height={6}
              viewBox="0 0 14 6"
              css={css({
                position: "absolute",
                top: "50%",
                right: -2,
                mt: 1,
                verticalAlign: "top",
                color: "text",
                transform: "translateX(100%) translateY(-50%)",
                opacity: 0.5,
              })}
            >
              <path d="M7 6l6.928-6H.072L7 6z" fill="currentColor" />
            </svg>
          </span>
          <br />
          in{` `}
          <span
            css={css({
              position: "relative",
              fontWeight: "bold",
              boxShadow: "inset 0 -1px 0 0 currentColor",
              cursor: "pointer",
            })}
            onClick={() => {
              setLocationOpen(!locationOpen)
            }}
            onKeyUp={event => {
              if (event.keyCode === 13) {
                event.preventDefault()
                setLocationOpen(!locationOpen)
              }
            }}
            // tabIndex={1}
          >
            {activeLocationFilters.length === 0 ? (
              "Locations"
            ) : activeLocationFilters.length === 1 ? (
              activeLocationFilters.toString()
            ) : (
              <span>
                Location<sup>{activeLocationFilters.length}</sup>
              </span>
            )}
            <svg
              width={14}
              height={6}
              viewBox="0 0 14 6"
              css={css({
                position: "absolute",
                top: "50%",
                right: -2,
                mt: 1,
                verticalAlign: "top",
                color: "text",
                transform: "translateX(100%) translateY(-50%)",
                opacity: 0.5,
              })}
            >
              <path d="M7 6l6.928-6H.072L7 6z" fill="currentColor" />
            </svg>
          </span>
        </H1>
        {subtext && (
          <RichText
            content={subtext}
            mt={[3, 4]}
            fontSize={[2, 3, 4]}
            css={css({
              maxWidth: 600,
            })}
          />
        )}
        {!full && (
          <Button
            children={`All move in ready homes`}
            mt={4}
            variant="default"
            as={Link}
            to={"/quick-possessions/"}
          />
        )}
      </Wrapper>
      <Wrapper
        as="section"
        py={[3, 4]}
        borderBottom="1px solid"
        borderColor="border"
      >
        <Flex
          flexWrap="wrap"
          alignItems="center"
          css={css({
            m: "-2px",
            "> *": {
              m: "2px",
            },
          })}
        >
          <SimpleSelect
            value={dateFilter}
            onChange={event => {
              setDateFilter(event.target.value)
            }}
          >
            <option children={`By date`} value="" disabled="true" />
            {dateFilterTypes.map((filter, index) => (
              <option
                children={filter.label}
                value={filter.value}
                key={`dateFilterOption` + index}
              />
            ))}
          </SimpleSelect>
          <SimpleSelect
            value={priceFilter}
            onChange={event => {
              setPriceFilter(event.target.value)
              setPriceFilterBottom(event.target.value.split("-")[0])
              setPriceFilterTop(event.target.value.split("-")[1])
            }}
          >
            <option children={`By price`} value="" disabled="true" />
            {priceFilterTypes.map((filter, index) => (
              <option
                children={filter.label}
                value={filter.value}
                key={`dateFilterOption` + index}
              />
            ))}
          </SimpleSelect>
          <SimpleSelect
            value={bedroomFilter}
            onChange={event => {
              setBedroomFilter(event.target.value)
            }}
          >
            <option children={`By bedrooms`} value="" disabled="true" />
            {bedroomFilterTypes.map((filter, index) => (
              <option
                children={filter.label}
                value={filter.value}
                key={`dateFilterOption` + index}
              />
            ))}
          </SimpleSelect>
          {(bedroomFilter || priceFilter || dateFilter) && (
            <H6
              children={`Reset filters`}
              ml={3}
              css={css({ cursor: "pointer" })}
              onClick={() => {
                resetFilters()
              }}
            />
          )}
        </Flex>
      </Wrapper>
      <Wrapper as="section" pt={[4, 5]}>
        <Flex
          flexWrap="wrap"
          css={css({
            mx: [-3, -4],
            my: -3,
            "> *": {
              width: ["100%", "50%", "33.333%"],
              px: [3, 4],
              py: 3,
            },
          })}
        >
          {houses &&
            houses.nodes &&
            houses.nodes
              .filter(home => {
                if (
                  activeLocationFilters.length === 0 ||
                  (home.data.community &&
                    home.data.community.document &&
                    home.data.community.document.data &&
                    home.data.community.document.data.location.document.data
                      .name &&
                    activeLocationFilters.indexOf(
                      home.data.community.document.data.location.document.data
                        .name
                    ) !== -1)
                ) {
                  return true
                } else {
                  return false
                }
              })
              .filter(home => {
                if (
                  activeTypeFilters.length === 0 ||
                  activeTypeFilters.indexOf(
                    home.data.home_category.document.data.name
                  ) !== -1
                ) {
                  return true
                } else {
                  return false
                }
              })
              .filter(home => {
                if (
                  (!bedroomFilter || home.data.bedrooms === bedroomFilter) &&
                  (!dateFilter || dateFilter === home.data.move_in_date) &&
                  (!priceFilter ||
                    (parseInt(home.data.price) >= priceFilterBottom &&
                      parseInt(home.data.price) <= priceFilterTop))
                ) {
                  return true
                } else {
                  return false
                }
              })
              .sort((a, b) => {
                if (a.data.name && b.data.name) {
                  return timeframeRanking[a.data.move_in_date] >
                    timeframeRanking[b.data.move_in_date]
                    ? -1
                    : 1
                } else {
                  return 0
                }
              })
              .map((home, index) => {
                return (
                  <HouseCard
                    status={home.data.project_status}
                    timeframe={home.data.move_in_date}
                    to={"/quick-possessions/" + home.uid + "/"}
                    image={home.data.image}
                    heading={home.data.name && home.data.name.text}
                    sticker={home.data.sticker}
                    subheading={
                      home.data.floorplan +
                      (home.data.community.document &&
                        home.data.community.document.data &&
                        home.data.community.document.data.name &&
                        ", " + home.data.community.document.data.name.text) +
                      (home.data.community.document &&
                        home.data.community.document.data &&
                        ", " +
                          home.data.community.document.data.location.document
                            .data.name)
                    }
                    meta={
                      (home.data.bedrooms && home.data.bedrooms + " Bed, ") +
                      (home.data.bathrooms && home.data.bathrooms + " Bath, ") +
                      (home.data.sq_footage &&
                        parseInt(home.data.sq_footage).toLocaleString() + " SF")
                    }
                    price={home.data.price}
                    key={`activeHome` + home.uid + index}
                  />
                )
              })}
        </Flex>
      </Wrapper>
      <Sidebar
        children={
          <>
            <H6
              children={`Location`}
              pt={[18, 24]}
              px={[3, 4]}
              fontSize={[0, 1, 2]}
              textAlign="right"
            />
            <Box pt={5} px={[3, 4]} css={css({ textAlign: "right" })}>
              {locations.sort().map(location => (
                <Option
                  label={location}
                  onClick={() => {
                    toggleLocation(location)
                  }}
                  key={"locationSelectorMoveIn" + location}
                  active={activeLocationFilters.indexOf(location) !== -1}
                />
              ))}
            </Box>
          </>
        }
        showDialog={locationOpen}
        setShowDialog={setLocationOpen}
        side="right"
      />
      <Sidebar
        children={
          <>
            <H6
              children={`Home Type`}
              pt={[18, 24]}
              px={[3, 4]}
              fontSize={[0, 1, 2]}
              textAlign="right"
            />
            <Box py={5} px={[3, 4]} css={css({ textAlign: "right" })}>
              {homeCategories.sort().map(homeCategory => (
                <Option
                  label={homeCategory}
                  onClick={() => {
                    toggleType(homeCategory)
                  }}
                  key={"homeCategorySelectorMoveIn" + homeCategory}
                  active={activeTypeFilters.indexOf(homeCategory) !== -1}
                />
              ))}
            </Box>
          </>
        }
        showDialog={typeOpen}
        setShowDialog={setTypeOpen}
        side="right"
      />
    </>
  )
}

export default HouseSelector
