import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"
import { rgba } from "polished"

import pattern from "images/pattern.png"

import AspectRatio from "../aspectRatio"
import Image from "../image"
import Box from "../box"
import Flex from "../flex"
import { H3 } from "../headings"
import Text from "../text"

const UpdateCard = ({ useLink, image, heading, excerpt, date, to }) => (
  <Box>
    <Box>
      <Box
        as={useLink ? Link : "div"}
        to={to}
        title={heading}
        aria-label={heading}
      >
        <AspectRatio ratio="4:3" bg={rgba("#3A3937", 0.2)}>
          {image && image.fluid && (
            <Image
              src={image}
              css={css({
                height: "100%",
              })}
            />
          )}
          {!image.fluid && (
            <Flex
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
              p={[3, 4]}
              css={css({
                backgroundImage: `url(${pattern})`,
                backgroundRepeat: "repeat",
                backgroundSize: "96px 96px",
                backgroundPosition: "50% 50%",
                backgroundOrigin: "50% 50%",
              })}
            >
              <H3 children={heading} textAlign="center" color="background" />
            </Flex>
          )}
        </AspectRatio>
      </Box>
      <Box>
        <H3 as="span" mt={[2, null, 3]} css={css({ display: "block" })}>
          <Box as={useLink ? Link : "span"} to={to}>
            {heading}
          </Box>
        </H3>
        <Text
          as="span"
          mt={1}
          fontSize={[1, 2]}
          css={css({ display: "block" })}
        >
          {excerpt}
        </Text>
        <Text
          as="span"
          mt={1}
          fontSize={[0, 1]}
          css={css({ display: "block" })}
        >
          {date}
        </Text>
      </Box>
    </Box>
  </Box>
)

export default UpdateCard
