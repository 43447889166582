import React, { useState } from "react"
import css from "@styled-system/css"
import { navigate, Link, graphql, useStaticQuery } from "gatsby"

import {
  Checkbox,
  RichText,
  Text,
  Box,
  Flex,
  Button,
  ColorMode,
  H3,
  H6,
  Input,
  Textarea,
  Pattern,
  Select,
  Wrapper,
  Sidebar,
} from "components"

function Refer({
  communities,
  contact,
  community,
  setCommunity,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  referralEmail,
  setReferralEmail,
  referralFirstName,
  referralLastName,
  setReferralFirstName,
  setReferralLastName,
  referralPhone,
  setReferralPhone,
  apiKey,
  setApiKey,
  message,
  setMessage,
  email,
  setEmail,
  phone,
  setPhone,
  postal,
  setPostal,
  consent,
  setConsent,
  submitCommunity,
  setError,
}) {
  return (
    <Box
      as="form"
      onSubmit={e => {
        e.preventDefault()
        let error = false
        setError("")
        if (!email || !/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
          error = true
          setError("Email must be a valid email address")
        }
        if (!community) {
          error = true
          setError("You must list what community you purchased in")
        }
        if (!phone) {
          error = true
          setError("You must enter a phone number")
        }
        if (!postal) {
          error = true
          setError("You must enter a valid postal code")
        }
        if (!firstName || !lastName) {
          error = true
          setError("Full name is required")
        }
        if (!apiKey) {
          error = true
          setError("You must have a community your referral is interested in.")
        }
        if (!referralEmail || !/(.+)@(.+){2,}\.(.+){2,}/.test(referralEmail)) {
          error = true
          setError("You must have a valid email listed for your referral.")
        }
        if (!referralFirstName || !referralLastName) {
          error = true
          setError("You must have a referral name listed.")
        }
        if (!referralPhone) {
          error = true
          setError("You must have a referral phone number listed.")
        }
        if (!consent) {
          error = true
          setError("You must consent to receiving emails to continue.")
        }
        if (!error) {
          submitCommunity(true)
        }
      }}
    >
      <input type="hidden" name="form-name" value="referAFriendForm" />
      {contact.data.referral_description && (
        <RichText
          css={css({ hyphens: "auto" })}
          content={contact.data.referral_description}
          mb={[3, 4, 5]}
        />
      )}
      <H6 children={`Your information`} />
      <Box mt={2}>
        <Input
          label={`First name`}
          name="firstName"
          value={firstName}
          onChange={event => {
            setFirstName(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Input
          label={`Last name`}
          name="lastName"
          value={lastName}
          onChange={event => {
            setLastName(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Input
          label={`Email`}
          name="email"
          type="email"
          value={email}
          onChange={event => {
            setEmail(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Input
          label={`Phone`}
          type="phone"
          name="phone"
          value={phone}
          onChange={event => {
            setPhone(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Input
          label={`Postal code`}
          name="postal"
          value={postal}
          onChange={event => {
            setPostal(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Input
          label={`Community`}
          type="text"
          name="community"
          value={community}
          onChange={event => {
            setCommunity(event.target.value)
          }}
        />
      </Box>
      <H6 children={`Who are you referring?`} mt={4} />
      <Box mt={2}>
        <Input
          label={`First name`}
          name="referralFirstName"
          value={referralFirstName}
          onChange={event => {
            setReferralFirstName(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Input
          label={`Last name`}
          name="referralLastName"
          value={referralLastName}
          onChange={event => {
            setReferralLastName(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Input
          label={`Email`}
          name="referralEmail"
          value={referralEmail}
          onChange={event => {
            setReferralEmail(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Input
          label={`Phone`}
          name="referralPhone"
          value={referralPhone}
          onChange={event => {
            setReferralPhone(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Select
          label={`Community`}
          value={apiKey}
          onChange={event => {
            setApiKey(event.target.value)
          }}
        >
          <option
            children={`What community is your referral looking into?`}
            disabled={true}
            value=""
          />
          {communities &&
            communities.nodes
              .sort((a, b) => {
                if (a.data.name && b.data.name) {
                  return a.data.name.text > b.data.name.text ? 1 : -1
                } else {
                  return 0
                }
              })
              .filter(community => {
                if (
                  community.data.project_status !== "Past" &&
                  community.data.project_status !== "Hidden"
                ) {
                  return true
                } else {
                  return false
                }
              })
              .map((community, index) => (
                <option
                  children={community.data.name && community.data.name.text}
                  value={community.data.name && community.data.name.text}
                  key={`communitySelect` + index}
                />
              ))}
        </Select>
      </Box>
      <Box mt={3}>
        <Textarea
          label={`Message (Optional)`}
          type="message"
          name="message"
          value={message}
          onChange={event => {
            setMessage(event.target.value)
          }}
        />
      </Box>
      <Box mt={1}>
        <Checkbox
          value={consent}
          onChange={() => {
            setConsent(!consent)
          }}
        >
          Yes, I wish receive emails from Partners Development Group. I
          understand that I can unsubscribe at any time.
        </Checkbox>
      </Box>
      <Box mt={4}>
        <Button children={`Submit`} variant="default" type="submit" />
      </Box>
    </Box>
  )
}

const General = ({
  contact,
  fullName,
  setFullName,
  email,
  setEmail,
  phone,
  setPhone,
  postal,
  setPostal,
  message,
  setMessage,
  consent,
  setConsent,
  submit,
  setError,
}) => (
  <Box
    as="form"
    onSubmit={e => {
      e.preventDefault()
      let error = false
      setError("")
      if (!email || !/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
        error = true
        setError("Email must be a valid email address")
      }
      if (!fullName) {
        error = true
        setError("Full name is required")
      }
      if (!phone) {
        error = true
        setError("Phone number is required")
      }
      if (!postal) {
        error = true
        setError("Please enter a valid postal code")
      }
      if (!message) {
        error = true
        setError("You must leave a short message for us to better assist you.")
      }
      if (!consent) {
        error = true
        setError("You must consent to receiving emails to continue.")
      }
      if (!error) {
        submit("generalContactForm")
      }
    }}
  >
    <input type="hidden" name="form-name" value="generalContactForm" />
    {contact.data.general_contact_description && (
      <RichText
        css={css({ hyphens: "auto" })}
        content={contact.data.general_contact_description}
        mb={[3, 4, 5]}
      />
    )}
    <Box>
      <Input
        label={`Full name`}
        name="fullName"
        value={fullName}
        onChange={event => {
          setFullName(event.target.value)
        }}
      />
    </Box>
    <Box mt={3}>
      <Input
        label={`Email`}
        name="email"
        type="email"
        value={email}
        onChange={event => {
          setEmail(event.target.value)
        }}
      />
    </Box>
    <Box mt={3}>
      <Input
        label={`Phone`}
        name="phone"
        type="phone"
        value={phone}
        onChange={event => {
          setPhone(event.target.value)
        }}
      />
    </Box>
    <Box mt={3}>
      <Input
        label={`Postal code`}
        name="postal"
        type="postal"
        value={postal}
        onChange={event => {
          setPostal(event.target.value)
        }}
      />
    </Box>
    <Box mt={3}>
      <Textarea
        label={`Message`}
        name="message"
        type="message"
        value={message}
        onChange={event => {
          setMessage(event.target.value)
        }}
      />
    </Box>
    <Box mt={1}>
      <Checkbox
        value={consent}
        onChange={() => {
          setConsent(!consent)
        }}
      >
        Yes, I wish receive emails from Partners Development Group. I understand
        that I can unsubscribe at any time.
      </Checkbox>
    </Box>
    <Box mt={4}>
      <Button children={`Submit`} variant="default" type="submit" />
    </Box>
  </Box>
)

function Community({
  setError,
  contact,
  communities,
  apiKey,
  setApiKey,
  submitCommunity,
  firstName,
  lastName,
  email,
  phone,
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  postal,
  setPostal,
  message,
  consent,
  setConsent,
  setMessage,
}) {
  return (
    <Box
      as="form"
      onSubmit={event => {
        event.preventDefault()
        let error = false
        setError("")
        if (!email || !/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
          error = true
          setError("Email must be a valid email address")
        }
        if (!firstName || !lastName) {
          error = true
          setError("Full name is required")
        }
        if (!phone) {
          error = true
          setError("Phone number is required")
        }
        if (!postal) {
          error = true
          setError("Please enter a valid postal code")
        }
        if (!apiKey) {
          error = true
          setError("You must select a community you are interested in.")
        }
        if (!consent) {
          error = true
          setError("You must consent to receiving emails to continue.")
        }
        if (!error) {
          submitCommunity(false)
        }
      }}
    >
      {contact.data.community_form_description && (
        <RichText
          css={css({ hyphens: "auto" })}
          content={contact.data.community_form_description}
          mb={[3, 4, 5]}
        />
      )}
      <Box>
        <Input
          label={`First name`}
          value={firstName}
          onChange={event => {
            setFirstName(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Input
          label={`Last name`}
          value={lastName}
          onChange={event => {
            setLastName(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Input
          label={`Phone number`}
          value={phone}
          onChange={event => {
            setPhone(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Input
          label={`Postal code`}
          value={postal}
          onChange={event => {
            setPostal(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Input
          label={`Email address`}
          value={email}
          onChange={event => {
            setEmail(event.target.value)
          }}
        />
      </Box>
      <Box mt={3}>
        <Select
          label={`Community`}
          value={apiKey}
          onChange={event => {
            setApiKey(event.target.value)
          }}
        >
          <option children={`Choose your community`} disabled={true} value="" />
          {communities &&
            communities.nodes
              .sort((a, b) => {
                if (a.data.name && b.data.name) {
                  return a.data.name.text > b.data.name.text ? 1 : -1
                } else {
                  return 0
                }
              })
              .filter(community => {
                if (
                  community.data.project_status !== "Past" &&
                  community.data.project_status !== "Hidden"
                ) {
                  return true
                } else {
                  return false
                }
              })
              .map((community, index) => (
                <option
                  children={community.data.name && community.data.name.text}
                  value={community.data.sales_simplicity_id}
                  key={`communitySelect` + index}
                />
              ))}
        </Select>
      </Box>
      <Box mt={3}>
        <Textarea
          label={`Message (Optional)`}
          type="message"
          name="message"
          value={message}
          onChange={event => {
            setMessage(event.target.value)
          }}
        />
      </Box>
      <Box mt={1}>
        <Checkbox
          value={consent}
          onChange={() => {
            setConsent(!consent)
          }}
        >
          Yes, I wish receive emails from Partners Development Group. I
          understand that I can unsubscribe at any time.
        </Checkbox>
      </Box>
      <Box mt={4}>
        <Button children={`Submit`} variant="default" type="submit" />
      </Box>
    </Box>
  )
}

const Buttons = ({ inquiryType, setInquiryType }) => (
  <Flex
    my={3}
    css={css({
      flexWrap: "wrap",
      m: [-1, -2],
      "> *": {
        width: ["100%", "initial"],
        p: [1, 2],
        "> *": {
          width: ["100%", "initial"],
        },
      },
    })}
  >
    <Box>
      <Button
        children={`General contact`}
        variant={"reverse"}
        onClick={() => {
          setInquiryType("general")
        }}
        id="contactButton"
      />
    </Box>
    {/* <Box>
      <Button
        children={`Refer a friend`}
        variant={"reverse"}
        onClick={() => {
          setInquiryType("refer")
        }}
      />
    </Box> */}
    <Box>
      <Button
        children={`Community inquiry`}
        variant={"reverse"}
        onClick={() => {
          setInquiryType("community")
        }}
        id="communityInquiry"
      />
    </Box>
    <Box>
      <Button
        children={`Home care/Warranty request`}
        variant="reverse"
        as={Link}
        to="/home-care/"
      />
    </Box>
  </Flex>
)

function Form({ heading, pattern, bg }) {
  const { communities, contact } = useStaticQuery(
    graphql`
      {
        communities: allPrismicCommunity {
          nodes {
            prismicId
            uid
            data {
              name {
                text
              }
              sales_simplicity_id
            }
          }
        }
        contact: prismicContact {
          data {
            general_contact_description {
              html
            }
            community_form_description {
              html
            }
            referral_description {
              html
            }
          }
        }
      }
    `
  )
  const [inquiryType, setInquiryType] = useState("")
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState("")
  const [apiKey, setApiKey] = useState("")
  const [referralFirstName, setReferralFirstName] = useState("")
  const [referralLastName, setReferralLastName] = useState("")
  const [referralEmail, setReferralEmail] = useState("")
  const [referralPhone, setReferralPhone] = useState("")
  const [community, setCommunity] = useState("")
  const [fullName, setFullName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [postal, setPostal] = useState("")
  const [message, setMessage] = useState("")
  const [consent, setConsent] = useState(false)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const resetProps = () => {
    setError("")
    setApiKey("")
    setFirstName("")
    setLastName("")
    setPhone("")
    setEmail("")
    setPhone("")
    setMessage("")
    setReferralPhone("")
    setReferralFirstName("")
    setReferralLastName("")
    setReferralEmail("")
    setCommunity("")
    setConsent("")
  }

  const handleFormSubmit = formName => {
    if (!processing) {
      setProcessing(true)
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": formName,
          email: email,
          phone: phone,
          postal: postal,
          message: message,
          fullName: fullName,
        }),
      })
        .then(() => {
          resetProps()
          setProcessing(false)
          setInquiryType("")
          navigate("/thank-you/")
        })
        .catch(error => {
          setProcessing(false)
          setError(error)
        })
    }
  }

  const submitCommunity = function(referral) {
    let error = false
    setError("")
    if (!email || !/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      error = true
      setError("Email must be a valid email address")
    }
    if (!firstName || !lastName) {
      error = true
      setError("First and last name are required")
    }
    if (!apiKey) {
      error = true
      setError("You must select a community")
    }
    if (!consent) {
      error = true
      setError("You must consent to receiving emails to continue.")
    }
    if (!error && !processing) {
      setProcessing(true)
      fetch("/.netlify/functions/register-sales-simplicity", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName: referral ? referralFirstName : firstName,
          lastName: referral ? referralLastName : lastName,
          projectId: apiKey,
          message: message,
          email: referral ? referralEmail : email,
          phone: referral ? referralPhone : phone,
          postal: postal,
          community: isNaN(apiKey) ? apiKey : community,
          leadType: referral ? "OR (Online Referral)" : "",
          referral: referral
            ? "This lead was referred by " +
              firstName +
              " " +
              lastName +
              ", who can be reached via phone at " +
              phone +
              ", or by email at " +
              email +
              " from the community " +
              community +
              " They included this message: " +
              message
            : "",
        }),
      })
        .then(response => {
          return response.json()
        })
        .then(body => {
          resetProps()
          setProcessing(false)
          if (body.success) {
            setInquiryType("")
            navigate("/thank-you/")
          }
        })
    }
  }

  return (
    <>
      <ColorMode mode="dark">
        <Box as={pattern ? Pattern : "div"}>
          <Wrapper bg={bg} flush>
            <Wrapper py={5}>
              <H3 mb={4}>{heading}</H3>
              <Box>
                <Buttons setInquiryType={setInquiryType} />
              </Box>
            </Wrapper>
          </Wrapper>
        </Box>
      </ColorMode>
      <Sidebar
        showDialog={inquiryType}
        setShowDialog={setInquiryType}
        maxWidth={[327, 512]}
        side="right"
      >
        <Box
          pt={[48, 64]}
          pb={5}
          px={[3, 4]}
          css={css({ transition: "opacity 200ms ease-in" })}
          style={{
            opacity: processing ? 0.5 : 1,
            cursor: processing ? "busy" : "",
          }}
        >
          {inquiryType === "community" && (
            <Community
              setError={setError}
              contact={contact}
              communities={communities}
              apiKey={apiKey}
              setApiKey={setApiKey}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              postal={postal}
              setPostal={setPostal}
              submitCommunity={submitCommunity}
              consent={consent}
              setConsent={setConsent}
              message={message}
              setMessage={setMessage}
            />
          )}
          {inquiryType === "refer" && (
            <Refer
              community={community}
              setCommunity={setCommunity}
              contact={contact}
              firstName={firstName}
              lastName={lastName}
              setFirstName={setFirstName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              postal={postal}
              setPostal={setPostal}
              setError={setError}
              referralFirstName={referralFirstName}
              referralLastName={referralLastName}
              referralPhone={referralPhone}
              referralEmail={referralEmail}
              setReferralEmail={setReferralEmail}
              setReferralPhone={setReferralPhone}
              setReferralFirstName={setReferralFirstName}
              setReferralLastName={setReferralLastName}
              communities={communities}
              apiKey={apiKey}
              setApiKey={setApiKey}
              message={message}
              setMessage={setMessage}
              consent={consent}
              setConsent={setConsent}
              submitCommunity={submitCommunity}
            />
          )}
          {inquiryType === "general" && (
            <General
              contact={contact}
              fullName={fullName}
              setFullName={setFullName}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              postal={postal}
              setPostal={setPostal}
              message={message}
              setMessage={setMessage}
              setError={setError}
              consent={consent}
              setConsent={setConsent}
              submit={handleFormSubmit}
            />
          )}
          {error && <Text children={error} mt={3} color="red" />}
        </Box>
      </Sidebar>
    </>
  )
}

Form.defaultProps = {
  bg: "brand.cyan.0",
  pattern: true,
}

export default Form
