import React from "react"
import css from "@styled-system/css"

import AspectRatio from "../aspectRatio"
import Box from "../box"
import Button from "../button"
import ColorMode from "../colorMode"
import Flex from "../flex"
import { H1 } from "../headings"
import Pattern from "../pattern"
import Wrapper from "../wrapper"
import RichText from "../richText"
import Image from "../image"
import PrismicLink from "../prismicLink"

const overlapAmount = 5

const OverlapFull = ({
  heading,
  text,
  buttonLabel,
  buttonLink,
  bg,
  image,
  alt,
  video,
  pattern,
  ...rest
}) => (
  <ColorMode mode="dark">
    <Box as="section" pt={[5, 6]} {...rest}>
      <Box
        as={
          pattern === "Pattern"
            ? Pattern
            : pattern === "No Pattern"
            ? "div"
            : "div"
        }
      >
        <Wrapper flush {...rest}>
          <Box bg={bg}>
            <Wrapper
              pt={[5, null, null, 6]}
              px={[3, 4, 5, 6]}
              pb={image && image.url ? overlapAmount : [5, null, null, 6]}
            >
              <Flex
                flexWrap="wrap"
                css={css({
                  m: [-2, -4],
                  "> *": {
                    p: [2, 4],
                  },
                })}
              >
                {heading && (
                  <Box width={["100%", 1 / 2]}>
                    <H1 as="h2">{heading.text}</H1>
                  </Box>
                )}
                {(text || (buttonLabel && buttonLink)) && (
                  <Box width={["100%", 1 / 2]}>
                    {text && <RichText content={text} />}
                    {buttonLabel && buttonLink && (
                      <PrismicLink link={buttonLink}>
                        <Button children={buttonLabel} mt={text && 4} />
                      </PrismicLink>
                    )}
                  </Box>
                )}
              </Flex>
            </Wrapper>
          </Box>
          {(video || image) && (
            <Wrapper position="relative">
              <Box
                css={css({
                  position: "absolute",
                  top: 0,
                  bottom: overlapAmount,
                  left: 0,
                  width: "100%",
                  bg: bg,
                })}
              />
              <Box
                position="relative"
                css={css({
                  overflow: "hidden",
                })}
              >
                {video && video.url && (
                  <AspectRatio ratio="4:3">
                    <video
                      preload="metadata"
                      autoPlay
                      loop
                      muted
                      playsInline
                      css={css({
                        position: "absolute !important",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        userSelect: "none",
                        pointerEvents: "none",
                        "@supports (-ms-ime-align:auto)": {
                          top: "50%",
                          left: " 50%",
                          transform: "translateX(-50%) translateY(-50%)",
                          minWidth: "100%",
                          minHeight: "100%",
                          width: "auto",
                          height: "auto",
                          overflow: "hidden",
                        },
                        "@media screen and (-ms-high-contrast: none)": {
                          top: "50%",
                          left: "50%",
                          transform: "translateX(-50%) translateY(-50%)",
                          minWidth: "100%",
                          minHeight: "100%",
                          width: "auto",
                          height: "auto",
                          overflow: "hidden",
                        },
                      })}
                    >
                      <source src={video.url + "#t0.5"} type="video/mp4" />
                    </video>
                  </AspectRatio>
                )}
                {(!video || !video.url) && image && (
                  <Image src={image} alt={alt && alt} />
                )}
              </Box>
            </Wrapper>
          )}
        </Wrapper>
      </Box>
    </Box>
  </ColorMode>
)

OverlapFull.defaultProps = {
  bg: "brand.green.0",
}

export default OverlapFull
