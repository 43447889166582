import React from "react"
import css from "@styled-system/css"
import { useTheme } from "@emotion/react"
import { rgba } from "polished"
import { mdiGoogle } from "@mdi/js"

import starGray from "images/star-gray.png"
import starGold from "images/star-gold.png"

import Box from "../box"
import Flex from "../flex"
import Icon from "../icon"
import Text from "../text"

const GoogleReviews = ({ link, rating, reviewCount }) => {
  const theme = useTheme()

  return (
    <div>
      <Flex
        as="a"
        href={link ? link : "#"}
        target="_blank"
        rel="noopener"
        alignItems="center"
        p={2}
        bg={rgba(theme.colors.text, 0.075)}
        borderRadius={9999}
      >
        <Icon
          title={`Google reviews`}
          symbol={mdiGoogle}
          size={1.5}
          color="text"
        />
        <Box ml={1} pr={2}>
          <Flex alignItems="center">
            <Text
              children={rating}
              as="span"
              mt="2px"
              mr="5px"
              fontSize={0}
              fontWeight="bold"
              lineHeight={1}
              color="#e7711b"
            />
            <Box
              aria-label={"Rated " + rating + " out of 5 stars"}
              css={css({
                width: 69,
                height: 13,
                backgroundImage: `url(${starGray})`,
                backgroundRepeat: "repeat-x",
              })}
            >
              <Box
                css={css({
                  width: Math.round((rating / 5) * 69),
                  height: 13,
                  backgroundImage: `url(${starGold})`,
                  backgroundRepeat: "repeat-x",
                })}
              />
            </Box>
          </Flex>
          <Text
            as="span"
            fontSize={0}
            lineHeight="1"
            css={css({ display: "block", mt: "4px" })}
          >
            <span
              css={css({
                textDecoration: "none",
                color: "inherit",
                backgroundImage: theme =>
                  `linear-gradient(to bottom, ${rgba(
                    theme.colors.text,
                    0.25
                  )} 50%, ${rgba(theme.colors.text, 0.25)} 50%)`,
                backgroundRepeat: "repeat-x",
                backgroundSize: "1px 1px",
                backgroundPosition: "0 100%",
              })}
            >
              {reviewCount} Google reviews
            </span>
          </Text>
        </Box>
      </Flex>
    </div>
  )
}

export default GoogleReviews
