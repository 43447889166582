import React, { useState } from "react"
import css from "@styled-system/css"

import { Box, Button, Form, Sidebar, Wrapper } from "components"

const CommunityRegisterButton = ({
  color,
  rounded,
  community,
  heading,
  salesSimplicityId,
}) => {
  const [registerOpen, setRegisterOpen] = useState(false)

  return (
    <div>
      <Button
        variant="default"
        intent="primary"
        css={css({ bg: color, mr: 4 })}
        onClick={() => {
          setRegisterOpen(true)
        }}
        rounded={rounded}
      >
        Register
      </Button>
      <Sidebar
        maxWidth={[327, 512]}
        children={
          <RegisterPopup
            color={color}
            community={community}
            heading={heading}
            salesSimplicityId={salesSimplicityId}
          />
        }
        showDialog={registerOpen}
        setShowDialog={setRegisterOpen}
        side="right"
      />
    </div>
  )
}

export default CommunityRegisterButton

function RegisterPopup({ community, heading, salesSimplicityId }) {
  return (
    <Wrapper as="section" pt={5} name="register-popup" id="register-popup">
      <Box pt={[48, 64]} pb={6}>
        <Form
          community={community}
          formFields={[
            "consent",
            "email",
            "firstName",
            "lastName",
            "message",
            "phone",
            "postal",
          ]}
          heading={`Register to stay informed about ${heading}?`}
          id="community-register-form"
          name="community-register-form"
          salesSimplicityId={salesSimplicityId}
          submitId={community + "_top-register"}
          submitLabel="Register"
          width="100%"
        />
      </Box>
    </Wrapper>
  )
}
