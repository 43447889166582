import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion"
import css from "@styled-system/css"

import Text from "../text"

function AccordionGroup({ id, items, onChange, preExpanded }) {
  return (
    <Accordion allowZeroExpanded={true} preExpanded={preExpanded}>
      {items.map((item, index) => (
        <AccordionItem
          key={"accordionGroup" + id + index}
          uuid={"accordionGroup" + id + index}
          id={item.id}
          css={css({
            pt: 3,
            borderTop: "1px solid",
            borderColor: "border",
            "&:not(:last-of-type)": {
              mb: 4,
            },
          })}
          onClick={() => {
            if (onChange) {
              onChange(item.object)
            }
          }}
        >
          <AccordionItemHeading
            css={css({
              fontFamily: "body",
              fontWeight: "bold",
              fontSize: [2, 3],
              lineHeight: "body",
              letterSpacing: "body",
              color: "text",
            })}
          >
            <AccordionItemButton
              css={css({
                display: "flex",
                cursor: "pointer",
                "&:focus": { outline: 0 },
                '&[aria-expanded="true"]': {
                  ".control": {
                    "> span:last-of-type": {
                      transform:
                        "translate(-50%, -50%) rotate(90deg) scaleX(0)",
                    },
                  },
                },
              })}
            >
              <span
                children={item.heading}
                css={css({
                  flex: 1,
                })}
              />
              <span
                className="control"
                css={css({
                  position: "relative",
                  display: "block",
                  width: "1em",
                  height: "1em",
                  mt: "2px",
                  ml: 3,
                  verticalAlign: "middle",
                })}
              >
                <span
                  css={css({
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    height: "1px",
                    bg: "text",
                    transform: "translate(-50%, -50%)",
                  })}
                />
                <span
                  css={css({
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    height: "1px",
                    bg: "text",
                    transform: "translate(-50%, -50%) rotate(90deg)",
                    transition: "transform 200ms ease-out",
                  })}
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel css={css({ pt: 3 })}>
            <Text>{item.children}</Text>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

AccordionGroup.defaultProps = {
  color: "text.default",
  id: "accordion",
  items: [],
}

export default AccordionGroup
