import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import Image from "../image"
import Box from "../box"
import Flex from "../flex"
import { H3 } from "../headings"
import Text from "../text"

const CommunityList = ({ image, heading, subheading, meta, to }) => (
  <Flex>
    <Link to={to}>
      {image && (
        <Box position="relative">
          <Image src={image} />
        </Box>
      )}
    </Link>
    <H3 mt={2}>
      <Link to={to} css={css({ textDecoration: "none" })}>
        {heading}
      </Link>
    </H3>
    <Text as="span" css={css({ display: "block" })}>
      {subheading}
    </Text>
    <Text as="span" mt={1} fontSize={1} css={css({ display: "block" })}>
      {meta}
    </Text>
  </Flex>
)

export default CommunityList
