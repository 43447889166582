import styled from "@emotion/styled"
import shouldForwardProp from "@styled-system/should-forward-prop"
import {
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
} from "styled-system"

const Flex = styled("div", {
  shouldForwardProp,
})(
  {
    minWidth: 0,
  },
  compose(
    border,
    color,
    flexbox,
    grid,
    layout,
    position,
    space
  )
)

Flex.defaultProps = { display: "flex" }

export default Flex
