import React from "react"

import Box from "../box"
import ContactForm from "../contactForm"

const Actions = () => (
  <>
    <Box pt={[5, 6]}>
      <ContactForm heading={`Want to get in touch?`} pattern={false} />
    </Box>
  </>
)

export default Actions
