import React, { useState, useEffect } from "react"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox"
import css from "@styled-system/css"

import pMark from "images/PDGmarker.png"

import { AspectRatio, Box, Flex, Text, Wrapper } from "components"

const GoogleMapInner = withScriptjs(
  withGoogleMap(props => {
    const {
      communityColor,
      amenities,
      zoom,
      center,
      activeCategory,
      activeLocation,
      setActiveLocation,
      showhome,
    } = props

    return (
      <GoogleMap
        defaultZoom={zoom || 14}
        center={{
          lat: center.latitude || amenities[1].location.latitude,
          lng: center.longitude || amenities[1].location.longitude,
        }}
        defaultOptions={{
          disableDefaultUI: false,
          clickableIcons: false,
          streetViewControl: false,
          navigationControl: true,
          mapTypeControl: false,
          mapTypeId: "roadmap",
          scrollwheel: true,
          draggable: true,
          styles: [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#f5f5f5",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#f5f5f5",
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e5e5e5",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ffffff",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#dadada",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "transit.line",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e5e5e5",
                },
              ],
            },
            {
              featureType: "transit.station",
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#c9c9c9",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
          ],
        }}
      >
        {amenities
          .filter(
            amenity =>
              amenity.category === activeCategory && amenity.location && true
          )
          .map((location, index) => (
            <Marker
              icon={{ url: "" }}
              key={"location" + activeCategory + location.amenity_name}
              position={{
                lat: location.location.latitude,
                lng: location.location.longitude,
              }}
            >
              <InfoBox
                options={{
                  alignBottom: true,
                  pane: "mapPane",
                  pixelOffset: new window.google.maps.Size(-12, 12),
                  closeBoxURL: ``,
                  enableEventPropagation: true,
                }}
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  width={24}
                  height={24}
                  borderRadius={9999}
                  bg="#3A3937"
                  css={css({ cursor: "pointer" })}
                  onClick={() => {
                    setActiveLocation(location)
                  }}
                >
                  <Text
                    children={index + 1}
                    fontSize={0}
                    fontWeight="bold"
                    textAlign="center"
                    color="#fff"
                  />
                </Flex>
              </InfoBox>
              {activeLocation &&
                activeLocation.amenity_name === location.amenity_name && (
                  <InfoBox
                    options={{
                      alignBottom: true,
                      pane: "mapPane",
                      pixelOffset: new window.google.maps.Size(-160, -16),
                      closeBoxURL: ``,
                      enableEventPropagation: true,
                    }}
                  >
                    <Flex justifyContent="center" width={320}>
                      <Box display="inline-block" bg="#3A3937" p={2}>
                        <Text
                          children={location.amenity_name}
                          fontSize={[0, 1]}
                          textAlign="center"
                          color="#fff"
                        />
                      </Box>
                    </Flex>
                  </InfoBox>
                )}
            </Marker>
          ))}
        {showhome && showhome.longitude && (
          <Marker
            icon={{ url: "" }}
            position={{
              lat: showhome.latitude,
              lng: showhome.longitude,
            }}
          >
            <InfoBox
              options={{
                alignBottom: true,
                pane: "mapPane",
                pixelOffset: new window.google.maps.Size(-24, 24),
                closeBoxURL: ``,
                enableEventPropagation: true,
              }}
            >
              <Box
                justifyContent="center"
                alignItems="center"
                width={48}
                height={48}
                borderRadius={9999}
                bg={communityColor}
                css={css({
                  backgroundImage: `url(${pMark})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                })}
              />
            </InfoBox>
          </Marker>
        )}
      </GoogleMap>
    )
  })
)

function AmenityMap({
  amenities,
  activeCategory,
  defaultCenter,
  zoom,
  communityColor,
  showhome,
}) {
  const [activeLocation, setActiveLocation] = useState()
  const [center, setCenter] = useState(defaultCenter || {})

  useEffect(() => {
    if (activeLocation) {
      setCenter({
        latitude: activeLocation.location.latitude,
        longitude: activeLocation.location.longitude,
      })
    }
  }, [activeLocation])

  return (
    <>
      <Wrapper as="section">
        <Flex
          flexWrap="wrap"
          css={css({
            m: [-3, null, -4],
            "> *": {
              p: [3, null, 4],
            },
          })}
        >
          <Box width={["100%", 2 / 3]}>
            <Box mx={[-3, 0]}>
              <AspectRatio ratio="4:3">
                <GoogleMapInner
                  zoom={zoom}
                  showhome={defaultCenter}
                  center={center}
                  amenities={amenities}
                  activeCategory={activeCategory}
                  googleMapURL={
                    "https://maps.googleapis.com/maps/api/js?key=" +
                    process.env.GATSBY_GOOGLE_KEY +
                    "&v=3.exp&libraries=geometry,drawing,places"
                  }
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  activeLocation={activeLocation}
                  setActiveLocation={setActiveLocation}
                  communityColor={communityColor}
                />
              </AspectRatio>
            </Box>
          </Box>
          <Box width={["100%", 1 / 3]}>
            <Box
              as="ol"
              my={0}
              p={0}
              css={css({
                listStyle: "none",
                "> *:not(:last-of-type)": {
                  mb: 2,
                },
              })}
            >
              {defaultCenter && (
                <Text
                  as="li"
                  fontSize={[1, 2, 3]}
                  css={css({ display: "flex", cursor: "pointer" })}
                  onClick={() => {
                    setActiveLocation({
                      amenity_name: "Showhome",
                      location: defaultCenter,
                    })
                  }}
                >
                  <Box
                    as="span"
                    display="block"
                    width="2em"
                    pr={[2, 3]}
                    color="alt"
                    css={css({
                      textAlign: "right",
                    })}
                  >
                    00
                  </Box>
                  <Box
                    as="span"
                    display="block"
                    style={{
                      fontWeight:
                        activeLocation &&
                        "Showhome" === activeLocation.amenity_name &&
                        600,
                    }}
                  >
                    Showhome
                  </Box>
                </Text>
              )}
              {amenities
                .filter(
                  amenity =>
                    amenity.category === activeCategory &&
                    amenity.location &&
                    true
                )
                .map((amenity, index) => (
                  <Text
                    as="li"
                    fontSize={[1, 2, 3]}
                    css={css({ display: "flex", cursor: "pointer" })}
                    onClick={() => {
                      setActiveLocation(amenity)
                    }}
                    key={`amenitySidebar` + activeCategory + index}
                  >
                    <Box
                      as="span"
                      display="block"
                      width="2em"
                      pr={[2, 3]}
                      color="alt"
                      css={css({
                        textAlign: "right",
                      })}
                    >
                      {index < 9 ? "0" + (index + 1) : index + 1}
                    </Box>
                    <Box
                      as="span"
                      display="block"
                      style={{
                        fontWeight:
                          activeLocation &&
                          amenity.amenity_name ===
                            activeLocation.amenity_name &&
                          600,
                      }}
                    >
                      {amenity.amenity_name}
                    </Box>
                  </Text>
                ))}
            </Box>
          </Box>
        </Flex>
      </Wrapper>
    </>
  )
}

export default AmenityMap
