import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"
import { animateScroll } from "react-scroll"
import { rgba } from "polished"

import Flex from "../flex"
import Text from "../text"

function Band() {
  return (
    <Flex
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      flex={1}
      css={css({
        mx: -2,
        my: "-2px",
        "> *": {
          px: 2,
          py: "2px",
        },
      })}
    >
      <Text fontSize={0}>
        <Link to="/privacy-policy/">Privacy Policy</Link>
      </Text>
      <Text fontSize={0}>
        <a href="https://builtbyfield.com/" target="_blank" rel="noopener">
          Design and code by Field
        </a>
      </Text>
      <Text
        ml={["initial", "auto"]}
        fontSize={0}
        textAlign="center"
        css={css({
          width: ["100%", "initial"],
        })}
      >
        <span
          css={css({
            textDecoration: "none",
            color: "inherit",
            backgroundImage: theme =>
              `linear-gradient(to bottom, ${rgba(
                theme.colors.text,
                0.25
              )} 50%, ${rgba(theme.colors.text, 0.25)} 50%)`,
            backgroundRepeat: "repeat-x",
            backgroundSize: "1px 1px",
            backgroundPosition: "0 100%",
            cursor: "pointer",
          })}
          role="button"
          onClick={function() {
            animateScroll.scrollToTop()
          }}
        >
          Scroll To Top
        </span>
      </Text>
    </Flex>
  )
}

export default Band
