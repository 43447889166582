import React, { useState } from "react"
import css from "@styled-system/css"
import { navigate } from "gatsby"

import {
  Button,
  Checkbox,
  Flex,
  Box,
  H3,
  Input,
  Text,
  Textarea,
} from "components"

var slugify = require("slugify")

const encode = function(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

const Form = props => {
  const {
    community,
    formFields,
    heading,
    id,
    name,
    salesSimplicityId,
    submitId,
    submitLabel,
    width,
  } = props

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState("")

  // Initialize formValues with a few default values
  const [formValues, setFormValues] = useState({
    community: salesSimplicityId ?? "Partners Development Group Ltd",
    builderName: "Partners",
    rank: "New Online Lead (Not rated - TO BE RANKED)",
  })

  if (!formValues) {
    setFormValues(createFormValues(formFields))
  }

  const submitForm = function(e) {
    let error = checkForErrorMessage(formValues)
    if (error) {
      setError(error)
    }

    if (!error && !processing) {
      setProcessing(true)

      fetch("/.netlify/functions/register-sales-simplicity", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...formValues,
        }),
      })
        .then(response => {
          return response.json()
        })
        .then(body => {
          setProcessing(false)
          if (body.success) {
            navigate("/thank-you/")
          }
        })
    }
  }

  return (
    <Box
      as="form"
      name={name}
      id={id}
      onSubmit={event => {
        event.preventDefault()
        submitForm(event)
      }}
      method="post"
      action="/thank-you/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      width={width}
      css={css({ transition: "opacity 200ms ease-in" })}
      style={{
        opacity: processing ? 0.5 : 1,
        cursor: processing ? "busy" : "",
      }}
    >
      <H3 mb={4} id={slugify(heading)}>
        {heading}
      </H3>
      <Flex
        flexWrap="wrap"
        css={css({
          mx: [-3, -2],
          my: -1,
          "> *": {
            width: "100%",
            px: [3, 2],
            py: 1,
          },
        })}
      >
        {formFields.includes("firstName") && (
          <Box>
            <Input
              label={`First name`}
              value={formValues?.firstName}
              onChange={event => {
                setFormValues({ ...formValues, firstName: event.target.value })
              }}
            />
          </Box>
        )}
        {formFields.includes("lastName") && (
          <Box>
            <Input
              label={`Last name`}
              value={formValues?.lastName}
              onChange={event => {
                setFormValues({ ...formValues, lastName: event.target.value })
              }}
            />
          </Box>
        )}
        {formFields.includes("phone") && (
          <Box>
            <Input
              label={`Phone number`}
              value={formValues?.phone}
              onChange={event => {
                setFormValues({ ...formValues, phone: event.target.value })
              }}
            />
          </Box>
        )}
        {formFields.includes("postal") && (
          <Box>
            <Input
              label={`Postal code`}
              value={formValues?.postal}
              onChange={event => {
                setFormValues({ ...formValues, postal: event.target.value })
              }}
            />
          </Box>
        )}
        {formFields.includes("email") && (
          <Box>
            <Input
              label={`Email address`}
              value={formValues?.email}
              onChange={event => {
                setFormValues({ ...formValues, email: event.target.value })
              }}
            />
          </Box>
        )}
        {formFields.includes("message") && (
          <Box>
            <Textarea
              label={`Message (Optional)`}
              type="message"
              name="message"
              value={formValues?.message}
              onChange={event => {
                setFormValues({ ...formValues, message: event.target.value })
              }}
            />
          </Box>
        )}
        {formFields.includes("consent") && (
          <Box mt={1} color={community?.color}>
            <Checkbox
              value={formValues?.consent}
              onChange={() => {
                setFormValues({ ...formValues, consent: !formValues?.consent })
              }}
            >
              Yes, I wish receive emails from Partners Development Group. I
              understand that I can unsubscribe at any time.
            </Checkbox>
          </Box>
        )}
      </Flex>
      <Box mt={[3, 4]}>
        <Button id={submitId} type="submit" variant="default">
          {submitLabel}
        </Button>
      </Box>
      {error && <Text children={error} mt={2} color="red" />}
    </Box>
  )
}

function checkForErrorMessage(formValues) {
  let error = null

  if (!formValues.email || !/(.+)@(.+){2,}\.(.+){2,}/.test(formValues.email)) {
    error = "Email must be a valid email address."
  }
  if (!formValues.phone) {
    error = "Please enter a valid phone number."
  }
  if (!formValues.postal) {
    error = "Please enter a valid postal code."
  }
  if (!formValues.firstName || !formValues.lastName) {
    error = "First and last name are required."
  }
  if (!formValues.consent) {
    error = "You must consent to receiving emails to continue."
  }
  return error
}

function createFormValues(formFields) {
  let formValues = []
  formFields.forEach(formField => {
    if (formField === "consent") {
      formValues[formField] = false
    } else {
      formValues[formField] = ""
    }
  })

  return formValues
}

export default Form
