import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import Box from "../box"
import Flex from "../flex"
import { H5 } from "../headings"
import Text from "../text"
import Wrapper from "../wrapper"

const Group = ({ children, label, width, multicolumn }) => (
  <Box as="section" width={width}>
    <H5 children={label} pb={2} />
    <Box
      pt={3}
      borderTop="1px solid"
      borderColor="border"
      css={css({ overflow: "hidden" })}
    >
      <Flex
        as="ul"
        flexWrap="wrap"
        css={css({
          mx: [-3, -4],
          my: -1,
          p: 0,
          listStyle: "none",
          "> *": {
            width: multicolumn ? "50%" : "100%",
            px: [3, 4],
            py: 1,
          },
        })}
      >
        {children}
      </Flex>
    </Box>
  </Box>
)

const NavItem = ({ label, to }) => (
  <Text as="li" fontSize={1}>
    <Link to={to} css={css({ textDecoration: "none" })}>
      {label}
    </Link>
  </Text>
)

const Upper = ({ communities }) => (
  <Wrapper as="section" py={[4, 5]}>
    <Flex
      flexWrap="wrap"
      css={css({
        m: [-3, -4],
        "> *": {
          p: [3, 4],
        },
      })}
    >
      <Group label={`Communities`} width={["100%", null, 1 / 2]} multicolumn>
        {communities
          .sort((a, b) => {
            if (a.data.name && b.data.name) {
              return a.data.name.text > b.data.name.text ? 1 : -1
            } else {
              return 0
            }
          })
          .map((community, index) => {
            if (
              community.data.project_status !== "Past" &&
              community.data.project_status !== "Hidden"
            ) {
              return (
                <NavItem
                  label={community.data.name && community.data.name.text}
                  to={"/communities/" + community.uid + "/"}
                  key={"footerCommunityLink" + index}
                />
              )
            } else {
              return null
            }
          })}
      </Group>
      <Group label={`Homes`} width={[1 / 2, 1 / 2, 1 / 4]}>
        <NavItem label={`Communities`} to="/communities/" />
        <NavItem label={`Showhomes`} to="/showhomes/" />
        <NavItem label={`Quick Possessions`} to="/quick-possessions/" />
        <NavItem label={`Home Care`} to="/home-care/" />
      </Group>
      <Group label={`Company`} width={[1 / 2, 1 / 2, 1 / 4]}>
        <NavItem label={`About`} to="/about/" />
        <NavItem label={`News`} to="/news/" />
        <NavItem label={`Past Communities`} to="/past-communities/" />
        <NavItem label={`Careers`} to="/careers/" />
        <NavItem label={`Contact`} to="/contact/" />
      </Group>
    </Flex>
  </Wrapper>
)

export default Upper
