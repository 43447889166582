import React, { useState } from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"
import { darken } from "polished"

import AspectRatio from "../aspectRatio"
import Image from "../image"
import Box from "../box"
import Flex from "../flex"
import { H3, H4, H6 } from "../headings"
import Text from "../text"

const Tag = ({ label, bg, top, left }) => (
  <Box
    position="absolute"
    top={top}
    left={left}
    display="inline-block"
    px={2}
    py={1}
    bg={bg}
  >
    <H6 children={label} color="background" />
  </Box>
)

function CommunityCard({
  color,
  image,
  alt,
  status,
  heading,
  subheading,
  meta,
  to,
  location,
}) {
  const [hovering, setHovering] = useState(false)

  return (
    <Link to={to}>
      <Box
        height="100%"
        onMouseEnter={() => {
          setHovering(true)
        }}
        onMouseLeave={() => {
          setHovering(false)
        }}
      >
        {image && (
          <AspectRatio ratio="4:3">
            <Image
              src={image}
              alt={alt}
              css={css({
                height: "100%",
              })}
            />
            <Tag
              label={status}
              top="16px"
              left="-8px"
              bg={status === "Now Selling" ? "brand.red.0" : "text"}
            />
          </AspectRatio>
        )}
        <Box>
          <Flex justifyContent="space-between" p={3} bg={color}>
            <Box>
              <H3 as="span" css={css({ display: "block" })} color="white">
                {heading}
                {location && (
                  <span css={{ opacity: 0.5, paddingLeft: 8 }}>{location}</span>
                )}
              </H3>
              {subheading && (
                <Text
                  as="span"
                  mt={1}
                  fontSize={[1, 2]}
                  color="white"
                  css={css({ display: "block" })}
                >
                  {subheading}
                </Text>
              )}
            </Box>
          </Flex>
          <Box p={3} bg={darken(0.05, color)}>
            {meta && (
              <Text
                as="span"
                fontSize={[0, 1]}
                fontWeight="bold"
                color="white"
                css={css({ display: "block" })}
              >
                {meta}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
    </Link>
  )
}

export default CommunityCard
