import styled from "@emotion/styled"
import shouldForwardProp from "@styled-system/should-forward-prop"
import { color, compose, space, textStyle, typography } from "styled-system"

const Text = styled("p", {
  shouldForwardProp,
})(
  {
    margin: 0,
  },
  compose(
    color,
    space,
    textStyle,
    typography
  )
)

Text.defaultProps = {
  fontSize: [1, 2],
  fontWeight: "body",
  lineHeight: "body",
  color: "text",
}

export default Text
