import React, { createContext, useContext, useState, useEffect } from "react"

export const HeaderContext = createContext()

export const HeaderProvider = HeaderProviderInner

function HeaderProviderInner({ children }) {
  const [headerState, setHeaderState] = useState("visible")
  const [headerMode, setHeaderMode] = useState("solid")

  const scrollListener = () => {
    const doc = document.documentElement
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    if (top >= 30) {
      setHeaderState("visible")
    } else {
      if (window.headerMode === "transparent") {
        setHeaderState("invisible")
      } else {
        setHeaderState("visible")
      }
    }
  }

  useEffect(() => {
    if (headerMode === "transparent") {
      setHeaderState("invisible")
    } else {
      setHeaderState("visible")
    }
    window.headerMode = headerMode
  }, [headerMode])

  useEffect(() => {
    window.addEventListener("scroll", scrollListener)
    return () => {
      window.removeEventListener("scroll", scrollListener)
    }
  }, [])

  return (
    <HeaderContext.Provider
      value={{
        headerState: headerState,
        headerMode: headerMode,
        setHeaderMode: setHeaderMode,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeader = () => useContext(HeaderContext)
