import React, { useState } from "react"
import { PreviewStoreProvider } from "gatsby-source-prismic"
import { Helmet } from "react-helmet"

import { motion, AnimatePresence } from "framer-motion"
import PropTypes from "prop-types"
import { Global, ThemeProvider } from "@emotion/react"
import css from "@styled-system/css"
import { rgba } from "polished"

import { HeaderProvider } from "contexts/header"

import { Footer, Header } from "components"

import theme from "./theme"

const Layout = ({ children, location }) => {
  const [tagColor, setTagColor] = useState("")
  // const [tagLabel, setTagLabel] = useState("Enquire now")

  // useEffect(() => {
  //   if (
  //     (location.pathname.indexOf("/communities/") !== -1 &&
  //       location.pathname !== "/communities/") ||
  //     location.pathname.indexOf("/quick-possessions/") !== -1
  //   ) {
  //     if (location.pathname === "/quick-possessions/") {
  //       setTagColor("")
  //     }
  //     setTagLabel("Get in touch")
  //   } else {
  //     setTagColor("")
  //     setTagLabel("Enquire now")
  //   }
  // }, [location.pathname])

  // const tagAction = () => {
  //   if (
  //     (location.pathname.indexOf("/communities/") !== -1 &&
  //       location.pathname !== "/communities/") ||
  //     (location.pathname.indexOf("/quick-possessions/") !== -1 &&
  //       location.pathname !== "/quick-possessions/")
  //   ) {
  //     scroller.scrollTo("community-contact-form", {
  //       duration: 500,
  //       smooth: true,
  //       offset: -192,
  //     })
  //   } else {
  //     const contactButton = document.getElementById("communityInquiry")
  //     if (contactButton) {
  //       contactButton.click()
  //     }
  //   }
  // }

  return (
    <PreviewStoreProvider>
      <ThemeProvider theme={theme}>
        <Helmet>
          <meta
            name="google-site-verification"
            content="f9Htm3Tus85A0tABdhC6HLjvYqt_HExar4HLZsaXl3Y"
          />
          <meta name="geo.region" content="CA-AB" />
          <meta name="geo.placename" content="Okotoks, Alberta" />
          <meta name="geo.position" content="50.728332, -113.931392" />
          <meta name="ICBM" content="50.728332, -113.931392" />
          <meta name="copyright" content="Partners Development Group" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="business.business" />
          <meta property="business:contact_data:locality" content="Okotoks" />
          <meta
            property="business:contact_data:street_address"
            content="#202, 802 Drake Landing Wynd"
          />
          <meta
            property="business:contact_data:postal_code"
            content="T1S 5R1"
          />
          <meta property="business:contact_data:region" content="Alberta" />
          <meta property="place:location:latitude" content="50.728332" />
          <meta property="place:location:longitude" content="-113.931392" />
        </Helmet>
        <HeaderProvider>
          <>
            <Global
              styles={css({
                "*": {
                  boxSizing: "border-box",
                },
                body: {
                  m: 0,
                  fontFamily: "body",
                  fontKerning: "normal",
                  color: "text",
                  bg: "background",
                  WebkitFontSmoothing: "antialiased",
                  MozOsxFontSmoothing: "grayscale",
                },
                a: { textDecoration: "none", color: "inherit" },
                "p, li, span": {
                  a: {
                    textDecoration: "none",
                    color: "inherit",
                    backgroundImage: theme =>
                      `linear-gradient(to bottom, ${rgba(
                        theme.colors.text,
                        0.25
                      )} 50%, ${rgba(theme.colors.text, 0.25)} 50%)`,
                    backgroundRepeat: "repeat-x",
                    backgroundSize: "1px 1px",
                    backgroundPosition: "0 100%",
                  },
                },
              })}
            />
            <Header location={location} />
            {/* <CTATag label={tagLabel} onClick={tagAction} color={tagColor} /> */}
            <AnimatePresence>
              <motion.main
                variants={{
                  initial: {
                    opacity: 0,
                  },
                  enter: {
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                      delay: 0.3,
                      when: "beforeChildren",
                    },
                  },
                  exit: {
                    opacity: 0,
                    transition: { duration: 0.3 },
                  },
                }}
                key={location.pathname}
                initial="initial"
                animate="enter"
                exit="exit"
              >
                {React.cloneElement(children, { setTagColor: setTagColor })}
              </motion.main>
            </AnimatePresence>
            <Footer />
          </>
        </HeaderProvider>
      </ThemeProvider>
    </PreviewStoreProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
