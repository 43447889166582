import React, { useLayoutEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import css from "@styled-system/css"

import "@reach/dialog/styles.css"

import closeCursor from "../../../static/images/closeCursor.png"

import Box from "../box"

const CloseButton = ({ side, ...rest }) => (
  <button
    title={`Close`}
    css={css({
      position: "absolute",
      top: 0,
      right: side === "left" && 0,
      left: side === "right" && 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: [48, 64],
      height: [48, 64],
      m: 0,
      p: 0,
      border: 0,
      bg: "transparent",
      appearance: "none",
      cursor: "pointer",
      "&:focus": { outline: 0 },
      "::-moz-focus-inner": { border: 0 },
    })}
    {...rest}
  >
    <svg
      width={24}
      height={24}
      viewBox="0 0 40 40"
      fill="none"
      css={css({ color: "text" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.914 20l9.293-9.293L29.5 10l-9.293 9.293L10.914 10l-.707.707L19.5 20 10 29.5l.707.707 9.5-9.5 9.5 9.5.707-.707-9.5-9.5z"
        fill="currentColor"
      />
    </svg>
  </button>
)

function Sidebar({
  id,
  children,
  maxWidth,
  side,
  showDialog,
  setShowDialog,
  scrollPosition,
}) {
  useLayoutEffect(() => {
    if (showDialog && scrollPosition) {
      setTimeout(() => {
        let scroller = document.getElementById(id + "sidebarScroll")
        let scrollItem = document.getElementById(scrollPosition)
        if (scroller && scrollItem) {
          scroller.scrollTop = scrollItem.getBoundingClientRect().top - 48
        }
      }, 100)
    }
  }, [showDialog])

  return (
    <AnimatePresence>
      {showDialog && (
        <DialogOverlay
          onDismiss={() => setShowDialog(!showDialog)}
          css={css({
            zIndex: 10000,
            bg: "transparent",
            cursor: `url(${closeCursor}), auto`,
            overflow: "hidden",
          })}
        >
          <motion.div
            variants={{
              initial: {
                opacity: 0,
              },
              enter: {
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              },
              exit: {
                opacity: 0,
                transition: { duration: 0.5 },
              },
            }}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <div
              css={css({
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: -1,
                width: "100%",
                height: "100%",
                bg: "text",
                opacity: 0.5,
              })}
            />
          </motion.div>
          <motion.div
            variants={{
              initial: {
                scaleX: 0,
              },
              enter: {
                scaleX: 1,
                transition: {
                  duration: 0.3,
                  when: "beforeChildren",
                },
              },
              exit: {
                scaleX: 0,
                transition: {
                  duration: 0.15,
                  when: "afterChildren",
                },
              },
            }}
            initial="initial"
            animate="enter"
            exit="exit"
            style={{ transformOrigin: side }}
          >
            <DialogContent
              css={css({
                position: "relative",
                zIndex: 3,
                width: "100%",
                maxWidth: maxWidth ? maxWidth : [327, 400],
                minHeight: "100vh",
                my: 0,
                p: 0,
                cursor: "auto",
                float: side,
                bg: "background",
              })}
            >
              <Box position="relative" width="100%" minHeight={["100vh"]}>
                <motion.div
                  variants={{
                    initial: {
                      opacity: 0,
                    },
                    enter: {
                      opacity: 1,
                      transition: {
                        duration: 0.2,
                      },
                    },
                    exit: {
                      opacity: 0,
                      transition: { duration: 0.1 },
                    },
                  }}
                >
                  <Box
                    css={css({
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 1,
                      width: "100%",
                      height: [48, 64],
                      bg: "background",
                    })}
                  >
                    <CloseButton
                      side={side}
                      onClick={() => setShowDialog(!showDialog)}
                    />
                  </Box>
                  <Box
                    pb={[4, 3]}
                    css={css({
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      WebkitOverflowScrolling: "touch",
                    })}
                    id={id + "sidebarScroll"}
                  >
                    {children}
                  </Box>
                </motion.div>
              </Box>
            </DialogContent>
          </motion.div>
        </DialogOverlay>
      )}
    </AnimatePresence>
  )
}

Sidebar.defaultProps = {
  side: "left",
}

export default Sidebar
