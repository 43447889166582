import React from "react"
import css from "@styled-system/css"

import Box from "../box"
import Flex from "../flex"
import { H6 } from "../headings"

const CTATag = ({ label, onClick, color }) => (
  <>
    <Box position="fixed" top="50%" right={0} zIndex={1000} onClick={onClick}>
      <Box
        css={css({
          position: "absolute",
          top: 0,
          left: 0,
          transform: "translateY(-133%) rotate(90deg)",
          transformOrigin: "0 0",
        })}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          height={["32px", "48px"]}
          px={3}
          bg={color || "text"}
          css={css({
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.125)",
            cursor: "pointer",
          })}
        >
          <H6
            as="span"
            color="background"
            css={css({
              whiteSpace: "nowrap",
            })}
          >
            {label}
          </H6>
        </Flex>
      </Box>
    </Box>
  </>
)

CTATag.defaultProps = {
  label: "Enquire now",
}

export default CTATag
