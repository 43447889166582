import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import Box from "../box"
import { H3, H6 } from "../headings"
import Text from "../text"

const Tag = ({ label, bg }) => (
  <Box
    position="absolute"
    top="16px"
    left="-8px"
    display="inline-block"
    px={2}
    py={1}
    bg={bg}
  >
    <H6 children={label} color="background" />
  </Box>
)

const HouseCard = ({ price, heading, subheading, meta, to }) => (
  <Box>
    <Link to={to}>
      <Box position="relative" pb="100%" bg="text">
        <Tag label={price} bg="brand.red.0" />
      </Box>
    </Link>
    <H3 mt={2}>
      <Link to={to} css={css({ textDecoration: "none" })}>
        {heading}
      </Link>
    </H3>
    <Text as="span" css={css({ display: "block" })}>
      {subheading}
    </Text>
    <Text as="span" mt={1} fontSize={1} css={css({ display: "block" })}>
      {meta}
    </Text>
  </Box>
)

HouseCard.defaultProps = {
  heading: "265-20 Silverado Crest Park SW",
  subheading: "A family-first community",
  meta: "Townhomes from $350,000",
  to: "/",
}

export default HouseCard
