import React from "react"

import Box from "../box"

const Wrapper = ({ bg, children, flush, maxWidth, ...rest }) => (
  <Box px={flush ? [0, null, 5] : [3, 4, 5]} {...rest}>
    <Box width="100%" maxWidth={maxWidth ? maxWidth : 1400} mx="auto" bg={bg}>
      {children}
    </Box>
  </Box>
)

export default Wrapper
