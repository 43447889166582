const transitionDelay = 300

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    if (!location.hash) {
      window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    }
  } else {
    if (!location.hash) {
      const savedPosition = getSavedScrollPosition(location)
      window.setTimeout(
        () => window.scrollTo(...(savedPosition || [0, 0])),
        transitionDelay
      )
    }
  }
  return false
}

const scrollTo = id => () => {
  const el = document.querySelector(id)
  if (el) return el.scrollIntoView()
  return false
}

export const onRouteUpdate = ({ location: { hash }, ...rest }) => {
  if (hash && rest.prevLocation) {
    window.setTimeout(scrollTo(hash), transitionDelay)
  }
}

export const onInitialClientRender = () => {
  if (window && window.location && window.location.hash) {
    window.setTimeout(scrollTo(window.location.hash), transitionDelay + 100)
  }
}
