import React from "react"
import css from "@styled-system/css"
import {
  mdiFacebookBox,
  mdiInstagram,
  mdiTwitter,
  mdiLinkedinBox,
  mdiYoutube,
} from "@mdi/js"

import Box from "../box"
import Flex from "../flex"
import Icon from "../icon"

const SocialButtonSimple = ({ href, icon, title }) => (
  <Box
    as="a"
    href={href}
    target="_blank"
    rel="noopener"
    title={title}
    aria-label={title}
    css={css({
      "&:not(:last-of-type)": {
        mr: 2,
      },
    })}
  >
    <Icon symbol={icon} title={title} size={1.5} color="text" />
  </Box>
)

const SocialLinks = ({ facebook, instagram, twitter, linkedin, youtube }) => (
  <Flex alignItems="center">
    {facebook && facebook.url && (
      <SocialButtonSimple
        title={`Facebook`}
        icon={mdiFacebookBox}
        href={facebook.url}
      />
    )}
    {twitter && twitter.url && (
      <SocialButtonSimple
        title={`Twitter`}
        icon={mdiTwitter}
        href={twitter.url}
      />
    )}
    {instagram && instagram.url && (
      <SocialButtonSimple
        title={`Instagram`}
        icon={mdiInstagram}
        href={instagram.url}
      />
    )}
    {youtube && youtube.url && (
      <SocialButtonSimple
        title={`YouTube`}
        icon={mdiYoutube}
        href={youtube.url}
      />
    )}
    {linkedin && linkedin.url && (
      <SocialButtonSimple
        title={`LinkedIn`}
        icon={mdiLinkedinBox}
        href={linkedin.url}
      />
    )}
  </Flex>
)

export default SocialLinks
