import React from "react"
import css from "@styled-system/css"

import pattern from "images/pattern.png"

import Box from "../box"

const Pattern = ({ children, ...rest }) => (
  <Box
    width="100%"
    maxWidth={1528}
    mx="auto"
    py={5}
    css={css({
      backgroundImage: `url(${pattern})`,
      backgroundRepeat: "repeat",
      backgroundSize: "96px 96px",
      backgroundPosition: "50% 50%",
      backgroundOrigin: "50% 50%",
    })}
    {...rest}
  >
    {children}
  </Box>
)

export default Pattern
