import React from "react"
import css from "@styled-system/css"
import { rgba } from "polished"
import uuidv4 from "uuid/v4"

import Box from "../box"
import Text from "../text"

const Textarea = ({ label, ...rest }) => {
  const id = uuidv4()

  return (
    <Box
      css={css({
        position: "relative",
        bg: theme => rgba(theme.colors.text, 0.1),
      })}
    >
      {label && (
        <Text
          children={label}
          as="label"
          htmlFor={id}
          fontSize={0}
          fontWeight="bold"
          css={css({
            position: "absolute",
            top: 2,
            right: 3,
            left: 3,
            display: "block",
            userSelect: "none",
          })}
        />
      )}
      <textarea
        id={id}
        css={css({
          flex: 1,
          width: "100%",
          minWidth: 0,
          m: 0,
          px: 3,
          pt: label ? 4 : "12px",
          pb: "12px",
          border: 0,
          borderRadius: 0,
          fontFamily: "body",
          fontSize: 16,
          fontWeight: "body",
          lineHeight: "body",
          letterSpacing: "body",
          verticalAlign: "top",
          color: "text",
          bg: "transparent",
          appearance: "none",
          "&:focus": { outline: 0 },
          "::placeholder": {
            color: "text",
            opacity: 0.75,
          },
        })}
        {...rest}
      />
    </Box>
  )
}

Textarea.defaultProps = {
  rows: 4,
}

export default Textarea
