import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import AspectRatio from "../aspectRatio"
import Image from "../image"
import Box from "../box"
import { H3, H6 } from "../headings"
import Text from "../text"

const Tag = ({ label, bg, top, left }) => (
  <Box
    position="absolute"
    top={top}
    left={left}
    display="inline-block"
    px={2}
    py={1}
    bg={bg}
  >
    <H6 children={label} color="background" />
  </Box>
)

const HouseCard = ({
  image,
  price,
  timeframe,
  heading,
  sticker,
  subheading,
  meta,
  to,
}) => (
  <Box>
    <Box>
      {image && (
        <Link to={to}>
          <AspectRatio ratio="1:1">
            <Image
              src={image}
              css={css({
                height: "100%",
              })}
            />
            {sticker && (
              <Box position="absolute" top="16px" right="16px" width="25%">
                <AspectRatio ratio="1:1">
                  <Image
                    src={sticker}
                    css={css({
                      height: "100%",
                    })}
                  />
                </AspectRatio>
              </Box>
            )}
            {price && (
              <Tag
                top="16px"
                left="-8px"
                label={"$" + parseInt(price).toLocaleString()}
                bg={"brand.red.0"}
              />
            )}
            {timeframe && (
              <Tag
                label={"Move In: " + timeframe}
                top={price ? "37px" : "16px"}
                left="-8px"
                bg="text"
              />
            )}
          </AspectRatio>
        </Link>
      )}
      <Box>
        <H3 as="span" mt={[2, null, 3]} css={css({ display: "block" })}>
          <Link to={to}>{heading}</Link>
        </H3>
        <Text
          as="span"
          mt={1}
          fontSize={[1, 2]}
          css={css({ display: "block" })}
        >
          {subheading}
        </Text>
        <Text
          as="span"
          mt={1}
          fontSize={[0, 1]}
          css={css({ display: "block" })}
        >
          {meta}
        </Text>
      </Box>
    </Box>
  </Box>
)

export default HouseCard
