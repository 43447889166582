import React from "react"
import css from "@styled-system/css"
import { hideVisually, rgba } from "polished"

import Box from "../box/"
import { H6 } from "../headings/"

import { colors } from "../../layouts/theme.js"

const Checkbox = ({ children, ...props }) => (
  <Box
    as="label"
    css={css({
      position: "relative",
      display: "inline-block",
      paddingLeft: 25,
      fontFamily: "sans",
      fontSize: "16px",
      lineHeight: 1.5,
      color: "text",
      cursor: "pointer",
    })}
  >
    <Box
      as="input"
      type="checkbox"
      css={css({ ...hideVisually() })}
      {...props}
    />
    <Box
      css={css({
        position: "absolute",
        top: "2px",
        left: 0,
        display: "block",
        width: 20,
        height: 20,
        borderRadius: "2px",
        backgroundColor: "background",
        backgroundImage:
          "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=)",
        backgroundSize: "50% 50%",
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        boxShadow: "inset 0 0 0 2px" + colors.brand.slate[0],
        userSelect: "none",
        ":hover": {
          boxShadow: "inset 0 0 0 2px " + colors.brand.slate[0],
        },
        "input:focus ~ &": {
          boxShadow: "inset 0 0 0 2px " + colors.brand.slate[0],
        },
        "input:checked ~ &": {
          backgroundColor: "currentColor",
          boxShadow: "inset 0 0 0 2px transparent",
        },
        "input:indeterminate ~ &": {
          backgroundImage:
            "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0wLDN2Mmg4VjNIMHoiLz4NCjwvc3ZnPg0K)",
        },
      })}
    />
    <H6>{children}</H6>
  </Box>
)

export default Checkbox
