import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import css from "@styled-system/css"

import Box from "../box"
import Wrapper from "../wrapper"

const LeftArrow = ({ onClick }) => (
  <Box
    as="button"
    position="absolute"
    bottom={-64}
    left={0}
    m={0}
    p={0}
    border={0}
    bg="transparent"
    css={css({ cursor: "pointer" })}
    onClick={onClick}
    aria-label="Previous slide"
  >
    <svg
      width={32}
      height={24}
      viewBox="0 0 33 25"
      fill="none"
      css={css({ verticalAlign: "top", color: "text" })}
    >
      <path
        d="M1.97 12.636H33M12.636 1L1 12.636l11.636 11.637"
        stroke="currentColor"
      />
    </svg>
  </Box>
)

const RightArrow = ({ onClick }) => (
  <Box
    as="button"
    position="absolute"
    bottom={-64}
    right={0}
    m={0}
    p={0}
    border={0}
    bg="transparent"
    css={css({ cursor: "pointer" })}
    onClick={onClick}
    aria-label="Next slide"
  >
    <svg
      width={32}
      height={24}
      viewBox="0 0 33 25"
      fill="none"
      css={css({ verticalAlign: "top", color: "text" })}
    >
      <path
        d="M31.03 12.636H0M20.364 1L32 12.636 20.364 24.273"
        stroke="currentColor"
      />
    </svg>
  </Box>
)

const defaultSettings = {
  infinite: false,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  nextArrow: <RightArrow />,
  prevArrow: <LeftArrow />,
}

function Carousel({ children, settings, ...rest }) {
  return (
    <Wrapper pt={[4, 5]} pb={5} css={css({ overflow: "hidden" })} {...rest}>
      <Slider
        css={css({
          ".slick-list": {
            overflow: "initial",
          },
          ".slick-track": {
            mx: "initial",
          },
        })}
        {...defaultSettings}
        {...settings}
      >
        {children}
      </Slider>
    </Wrapper>
  )
}

Carousel.defaultProps = {
  borderTop: "1px solid",
  borderColor: "border",
}

export default Carousel
