import styled from "@emotion/styled"
import shouldForwardProp from "@styled-system/should-forward-prop"
import {
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
} from "styled-system"

const Box = styled("div", {
  shouldForwardProp,
})(
  {
    minWidth: 0,
  },
  compose(
    border,
    color,
    flexbox,
    grid,
    layout,
    position,
    space
  )
)

export default Box
