import React from "react"
import css from "@styled-system/css"

import AspectRatio from "../aspectRatio"
import Box from "../box"
import Button from "../button"
import ColorMode from "../colorMode"
import Flex from "../flex"
import { H1, H2 } from "../headings"
import Pattern from "../pattern"
import Wrapper from "../wrapper"
import RichText from "../richText"
import Image from "../image"
import PrismicLink from "../prismicLink"

const overlapAmount = 6

const OverlapHalf = ({
  heading,
  large,
  text,
  button,
  link,
  align,
  bg,
  image,
  alt,
  video,
  pattern,
  ...rest
}) => (
  <ColorMode mode="dark">
    <Box as="section" pt={[5, 6]} {...rest}>
      <Box
        as={
          pattern === "Pattern"
            ? Pattern
            : pattern === "No Pattern"
            ? "div"
            : "div"
        }
      >
        <Wrapper flush>
          <Flex flexWrap="wrap">
            <Flex
              position="relative"
              flexDirection="column"
              order={align === "Right" ? [-1, null, 1] : -1}
              width={["100%", null, 1 / 2]}
              bg={bg}
            >
              <Flex flex={1} alignItems="center" py={[5, null, overlapAmount]}>
                <Wrapper px={[3, 4, 5, 6]}>
                  {heading && large && <H1 as="h1">{heading.text}</H1>}
                  {heading && !large && <H2 as="h2">{heading.text}</H2>}
                  {text && <RichText mt={heading && [3, 4]} content={text} />}
                  {button && link && (
                    <PrismicLink link={link}>
                      <Button children={button} mt={(heading || text) && 4} />
                    </PrismicLink>
                  )}
                </Wrapper>
              </Flex>
              <Box
                css={css({
                  position: "absolute",
                  top: 0,
                  right: align === "Left" && 0,
                  left: align === "Right" && 0,
                  display: ["none", null, "block"],
                  width: overlapAmount,
                  height: "100%",
                  bg: bg,
                  transform:
                    align === "Right"
                      ? "translateX(-100%)"
                      : "translateX(100%)",
                })}
              />
            </Flex>
            {(video || image) && (
              <Flex
                position="relative"
                zIndex={1}
                flexDirection="column"
                justifyContent="center"
                width={["100%", null, 1 / 2]}
              >
                <Box py={[0, null, overlapAmount]}>
                  {video && video.url && (
                    <AspectRatio ratio="4:3">
                      <video
                        preload="metadata"
                        autoPlay
                        loop
                        muted
                        playsInline
                        css={css({
                          position: "absolute !important",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          userSelect: "none",
                          pointerEvents: "none",
                          "@supports (-ms-accelerator: true)": {
                            top: "50%",
                            left: " 50%",
                            transform: "translateX(-50%) translateY(-50%)",
                            minWidth: "100%",
                            minHeight: "100%",
                            width: "auto",
                            height: "auto",
                            overflow: "hidden",
                          },
                          "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
                            top: "50%",
                            left: " 50%",
                            transform: "translateX(-50%) translateY(-50%)",
                            minWidth: "100%",
                            minHeight: "100%",
                            width: "auto",
                            height: "auto",
                            overflow: "hidden",
                          },
                        })}
                      >
                        <source src={video.url + "#t0.5"} type="video/mp4" />
                      </video>
                    </AspectRatio>
                  )}
                  {(!video || !video.url) && image && (
                    <Image src={image} alt={alt && alt} />
                  )}
                </Box>
              </Flex>
            )}
          </Flex>
        </Wrapper>
      </Box>
    </Box>
  </ColorMode>
)

OverlapHalf.defaultProps = {
  align: "left",
  bg: "brand.green.0",
}

export default OverlapHalf
