import React from "react"
import css from "@styled-system/css"

import Box from "../box"

function RichText({ content, fontSize, ...rest }) {
  if (content && content.html) {
    return (
      <Box
        dangerouslySetInnerHTML={{ __html: content.html }}
        css={css({
          fontSize: fontSize || [1, 2],
          fontWeight: "body",
          lineHeight: "body",
          color: "text",
          "> *": {
            m: 0,
            mt: "1em",
          },
          '[data-oembed-type="video"]': {
            position: "relative",
            my: 3,
            pb: (270 / 480) * 100 + "%",
            iframe: {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            },
          },
        })}
        {...rest}
      />
    )
  } else {
    return null
  }
}

export default RichText
