import React from "react"
import Box from "../box"
import css from "@styled-system/css"

const AspectRatio = ({ children, ratio, ...rest }) => {
  const padding = ratio.split(":")

  return (
    <Box
      css={css({
        position: "relative",
        width: "100%",
        pb: (padding[1] / padding[0]) * 100 + "%",
      })}
      {...rest}
    >
      <Box
        css={css({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        })}
      >
        {children}
      </Box>
    </Box>
  )
}

AspectRatio.defaultProps = {
  ratio: "16:9",
}

export default AspectRatio
