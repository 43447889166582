import React, { useEffect } from "react"
import css from "@styled-system/css"

import { useHeader } from "contexts/header"

import { Box, ColorMode, Flex, H1, H2, Image, Wrapper } from "components"

function PageHeader({ image, video, title, subtitle }) {
  const { setHeaderMode } = useHeader()

  useEffect(() => {
    if ((image && image.url) || (video && video.url)) {
      setHeaderMode("transparent")
    } else {
      setHeaderMode("solid")
    }
  }, [])
  return (
    <ColorMode mode={image.url || video.url ? "dark" : "light"}>
      <Box
        as="header"
        position="relative"
        width="100%"
        mx="auto"
        pt={image.url || video.url ? 0 : 64}
        css={css({
          overflow: "hidden",
        })}
      >
        {video && (
          <video
            preload="metadata"
            autoPlay
            muted
            playsInline
            css={css({
              position: "absolute !important",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              userSelect: "none",
              pointerEvents: "none",
              "@supports (-ms-ime-align:auto)": {
                top: "50%",
                left: " 50%",
                transform: "translateX(-50%) translateY(-50%)",
                minWidth: "100%",
                minHeight: "100%",
                width: "auto",
                height: "auto",
                overflow: "hidden",
              },
              "@media screen and (-ms-high-contrast: none)": {
                top: "50%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
                minWidth: "100%",
                minHeight: "100%",
                width: "auto",
                height: "auto",
                overflow: "hidden",
              },
            })}
          >
            <source src={video.url + "#t0.5"} type="video/mp4" />
          </video>
        )}
        {image && (
          <Image
            src={image}
            alt={image.alt}
            loading="eager"
            css={css({
              position: "absolute !important",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              userSelect: "none",
              pointerEvents: "none",
            })}
          />
        )}
        <Wrapper
          position="relative"
          minHeight={(image.url || video.url) && "75vh"}
          css={css({
            background:
              (image.url || video.url) &&
              `linear-gradient(
              to bottom,
              hsla(0, 0%, 0%, 0) 0%,
              hsla(0, 0%, 0%, 0.001) 14.9%,
              hsla(0, 0%, 0%, 0.004) 27.8%,
              hsla(0, 0%, 0%, 0.01) 38.8%,
              hsla(0, 0%, 0%, 0.017) 48.2%,
              hsla(0, 0%, 0%, 0.028) 56.1%,
              hsla(0, 0%, 0%, 0.041) 62.8%,
              hsla(0, 0%, 0%, 0.057) 68.5%,
              hsla(0, 0%, 0%, 0.075) 73.3%,
              hsla(0, 0%, 0%, 0.097) 77.5%,
              hsla(0, 0%, 0%, 0.122) 81.2%,
              hsla(0, 0%, 0%, 0.151) 84.6%,
              hsla(0, 0%, 0%, 0.182) 88.1%,
              hsla(0, 0%, 0%, 0.218) 91.6%,
              hsla(0, 0%, 0%, 0.257) 95.5%,
              hsla(0, 0%, 0%, 0.3) 100%
            )`,
          })}
        >
          <Flex
            flexDirection="column"
            flex={1}
            minHeight={(image.url || video.url) && "75vh"}
            pt={image.url || video.url ? [192, 320] : [5, 6]}
          >
            {title && title.text && (
              <H2 as="h1" mt="auto">
                {title.text ? title.text : title}
              </H2>
            )}
            {subtitle && subtitle.text && (
              <>
                <H1 as="h2" mt={[12, 3]} fontWeight="bold">
                  {subtitle.text}
                </H1>
              </>
            )}
            {(image.url || video.url) && (
              <Box
                width="100%"
                mt={[24, 48]}
                pb={[4, 5]}
                borderTop="1px solid"
                borderColor="text"
              />
            )}
          </Flex>
        </Wrapper>
      </Box>
    </ColorMode>
  )
}

export default PageHeader
