import React from "react"

import { Link as ScrollLink } from "react-scroll"
import { Link } from "gatsby"

function PrismicLink({ link, newTab, children }) {
  if (link) {
    //if link has #, use a scroll Link
    if (
      link.url &&
      (link.url.indexOf("http://#") !== -1 ||
        link.url.indexOf("https://#") !== -1)
    ) {
      return (
        <ScrollLink
          aria-label={`Scroll down`}
          role="button"
          to={link.url.replace("http://#", "").replace("https://#", "")}
          smooth={true}
          offset={-96}
        >
          {children}
        </ScrollLink>
      )
    } else {
      //if link doesn't have standard HTML setup, use internal link
      if (
        link.url &&
        link.url.indexOf("http://") === -1 &&
        link.url.indexOf("https://") === -1 &&
        link.url.indexOf("tel:") === -1 &&
        link.url.indexOf("mailto:") === -1
      ) {
        return <Link to={link.url}>{children}</Link>
      } else {
        if (!link.link_type) {
          return null
        } else {
          //else use standard anchor
          return (
            <a
              href={link.url}
              rel={newTab && "noopener"}
              target={newTab && "_blank"}
            >
              {children}
            </a>
          )
        }
      }
    }
  } else {
    return null
  }
}

PrismicLink.defaultProps = {
  newTab: true,
}

export default PrismicLink
