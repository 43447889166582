import React from "react"
import styled from "@emotion/styled"
import css from "@styled-system/css"
import shouldForwardProp from "@styled-system/should-forward-prop"
import { buttonStyle, compose, space } from "styled-system"
import { motion } from "framer-motion"
import { rgba } from "polished"

const buttonVariants = {
  initial: {
    scale: 1,
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0)",
  },
  hover: {
    scale: 1.05,
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.125)",
  },
  tap: {
    scale: 1,
  },
}

const buttonRoundedVariants = {
  initial: {
    scale: 1.2,
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0)",
  },
  hover: {
    scale: 1.25,
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.125)",
  },
  tap: {
    scale: 1.2,
  },
}

const fillVariants = {
  initial: {
    opacity: 0,
    scale: 0,
  },
  hover: {
    opacity: 1,
    scale: 2,
  },
}

const textOneVariants = {
  initial: {
    opacity: 1,
    y: 0,
  },
  hover: {
    opacity: 0,
    y: "-225%",
  },
}

const textTwoVariants = {
  initial: {
    opacity: 0,
    y: "175%",
  },
  hover: {
    opacity: 1,
    y: "-50%",
  },
}

const Comp = motion.custom(
  styled("button", {
    shouldForwardProp,
  })(
    css({
      position: "relative",
      display: "inline-block",
      minWidth: [128, 192],
      m: 0,
      p: [16, 20],
      border: 0,
      borderRadius: 0,
      fontFamily: "body",
      fontSize: 0,
      fontWeight: 700,
      lineHeight: 1.25,
      letterSpacing: "caps",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "uppercase",
      verticalAlign: "top",
      color: "text",
      bg: "primary",
      overflow: "hidden",
      appearance: "none",
      cursor: "pointer",
      userSelect: "none",
      "&:focus": { outline: 0 },
    }),
    compose(buttonStyle, space)
  )
)

const Button = ({ children, rounded, ...rest }) => (
  <Comp
    initial="initial"
    whileHover="hover"
    whileTap="tap"
    variants={!rounded ? buttonVariants : buttonRoundedVariants}
    css={css(
      rounded && {
        borderTopLeftRadius: 24,
        borderBottomLeftRadius: 24,
        borderTopRightRadius: 24,
      }
    )}
    {...rest}
  >
    <motion.span
      variants={fillVariants}
      css={css({
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        pb: "100%",
        borderRadius: 9999,
        bg: rgba("#000", 0.1),
        pointerEvents: "none",
      })}
    />
    <motion.span
      children={children}
      variants={textOneVariants}
      css={css({
        display: "block",
        transform: "translateY(0)",
        pointerEvents: "none",
      })}
    />
    <motion.span
      children={children}
      variants={textTwoVariants}
      css={css({
        position: "absolute",
        top: "50%",
        left: 0,
        width: "100%",
        textAlign: "center",
        transform: "translateY(-50%)",
        pointerEvents: "none",
      })}
    />
  </Comp>
)

Button.defaultProps = {
  variant: "reverse",
}

export default Button
