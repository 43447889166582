import { rgba, tint } from "polished"

export const colors = {
  text: "#3A3937",
  alt: rgba("#3A3937", 0.5),
  background: "#fff",
  border: rgba("#3A3937", 0.2),
  brand: {
    gold: ["#ab8900", tint(0.25, "#ab8900"), tint(0.5, "#ab8900")],
    ochre: ["#ce7a23", tint(0.25, "#ce7a23"), tint(0.5, "#ce7a23")],
    red: ["#c14729", tint(0.25, "#c14729"), tint(0.5, "#c14729")],
    maroon: ["#aa182c", tint(0.25, "#aa182c"), tint(0.5, "#aa182c")],
    plum: ["#8d0e57", tint(0.25, "#8d0e57"), tint(0.5, "#8d0e57")],
    purple: ["#6c3175", tint(0.25, "#6c3175"), tint(0.5, "#6c3175")],
    blue: ["#004876", tint(0.25, "#004876"), tint(0.5, "#004876")],
    cyan: ["#4c86a0", tint(0.25, "#4c86a0"), tint(0.5, "#4c86a0")],
    teal: ["#007473", tint(0.25, "#007473"), tint(0.5, "#007473")],
    lime: ["#789904", tint(0.25, "#789904"), tint(0.5, "#789904")],
    green: ["#40683c", tint(0.25, "#40683c"), tint(0.5, "#40683c")],
    slate: ["#65665d", tint(0.25, "#65665d"), tint(0.5, "#65665d")],
  },
  modes: {
    dark: {
      text: "#fff",
      alt: rgba("#fff", 0.5),
      background: "#3A3937",
      border: rgba("#fff", 0.2),
      brand: {
        gold: ["#ab8900", tint(0.25, "#ab8900"), tint(0.5, "#ab8900")],
        ochre: ["#ce7a23", tint(0.25, "#ce7a23"), tint(0.5, "#ce7a23")],
        red: ["#c14729", tint(0.25, "#c14729"), tint(0.5, "#c14729")],
        maroon: ["#aa182c", tint(0.25, "#aa182c"), tint(0.5, "#aa182c")],
        plum: ["#8d0e57", tint(0.25, "#8d0e57"), tint(0.5, "#8d0e57")],
        purple: ["#6c3175", tint(0.25, "#6c3175"), tint(0.5, "#6c3175")],
        blue: ["#004876", tint(0.25, "#004876"), tint(0.5, "#004876")],
        cyan: ["#4c86a0", tint(0.25, "#4c86a0"), tint(0.5, "#4c86a0")],
        teal: ["#007473", tint(0.25, "#007473"), tint(0.5, "#007473")],
        lime: ["#789904", tint(0.25, "#789904"), tint(0.5, "#789904")],
        green: ["#40683c", tint(0.25, "#40683c"), tint(0.5, "#40683c")],
        slate: ["#65665d", tint(0.25, "#65665d"), tint(0.5, "#65665d")],
      },
    },
  },
}

export default {
  breakpoints: ["40em", "64em", "80em"],
  colors,
  fonts: {
    body: `'Montserrat', system-ui, sans-serif`,
    heading: `'Montserrat', system-ui, sans-serif`,
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 18, 24, 32, 40],
  fontWeights: {
    body: "400",
    heading: "300",
    bold: "600 ",
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  letterSpacings: {
    body: "-0.02em",
    heading: "-0.04em",
    caps: "0.02em",
  },
  space: [0, 4, 8, 16, 32, 64, 128],
  sizes: [0, 4, 8, 16, 32, 64, 128],
  easing: {
    standard: `cubic-bezier(0.4, 0.0, 0.2, 1)`,
    enter: `cubic-bezier(0.0, 0.0, 0.2, 1)`,
    exit: `cubic-bezier(0.4, 0.0, 1, 1)`,
  },
  textStyles: {
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.02em",
    },
  },
  buttons: {
    default: {
      color: colors.background,
      backgroundColor: colors.text,
    },
    reverse: {
      color: colors.text,
      backgroundColor: colors.background,
    },
    alt: {
      color: colors.text,
      backgroundColor: rgba(colors.text, 0.1),
    },
  },
}
