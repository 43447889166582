import React from "react"
import css from "@styled-system/css"
import { rgba, triangle } from "polished"
import uuidv4 from "uuid/v4"

import Box from "../box"
import Text from "../text"

const Select = ({ children, label, ...rest }) => {
  const id = uuidv4()

  return (
    <Box
      css={css({
        position: "relative",
        bg: theme => rgba(theme.colors.text, 0.1),
      })}
    >
      {label && (
        <Text
          children={label}
          as="label"
          htmlFor={id}
          fontSize={0}
          fontWeight="bold"
          css={css({
            position: "absolute",
            top: 2,
            right: 3,
            left: 3,
            display: "block",
            userSelect: "none",
          })}
        />
      )}
      <div
        css={css({
          position: "relative",
          display: "inline-block",
          width: "100%",
        })}
      >
        <div
          css={css({
            position: "absolute",
            top: "50%",
            right: "16px",
            mt: "10px",
            color: "text",
            ...triangle({
              pointingDirection: "bottom",
              width: "10px",
              height: "5px",
              foregroundColor: "currentColor",
            }),
            transform: "translateY(-50%)",
            pointerEvents: "none",
          })}
        />
        <select
          id={id}
          css={css({
            display: "inline-block",
            width: "100%",
            m: 0,
            pt: label ? 4 : "12px",
            pb: "12px",
            pr: "40px",
            pl: 3,
            border: 0,
            borderRadius: 0,
            outline: 0,
            fontFamily: "body",
            fontSize: "16px",
            lineHeight: "body",
            letterSpacing: "body",
            color: "text",
            bg: "transparent",
            cursor: "pointer",
            appearance: "none",
            ":focus": {
              outline: 0,
            },
            "::-ms-expand": {
              display: "none",
            },
            ":focus:-moz-focusring": {
              color: "transparent",
              textShadow: "0 0 0 #000",
            },
          })}
          {...rest}
        >
          {children}
        </select>
      </div>
    </Box>
  )
}

export default Select
