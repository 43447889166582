import React from "react"

import OverlapHalf from "../overlapHalf"
import OverlapFull from "../overlapFull"

const CallToAction = ({ style = "Half", heading, text, bg, ...rest }) => {
  if ((heading && heading.text) || (text && text.html)) {
    if (style) {
      if (style === "Half") {
        return (
          <OverlapHalf
            text={text}
            heading={heading}
            bg={bg || "#004876"}
            {...rest}
          />
        )
      }
      if (style === "Full") {
        return (
          <OverlapFull
            text={text}
            heading={heading}
            bg={bg || "#004876"}
            {...rest}
          />
        )
      }
    } else {
      return null
    }
  } else {
    return null
  }
}

export default CallToAction
