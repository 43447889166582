import React from "react"
import css from "@styled-system/css"

import Box from "../box"
import ColorMode from "../colorMode"
import Flex from "../flex"
import Wrapper from "../wrapper"
import RichText from "../richText"
import Image from "../image"
import { H1 } from "../headings"

const overlapAmount = 5

const Split = ({ heading, text, align, bg, image, alt, ...rest }) => {
  return (
    <Box as="section" pt={[5, 6]} {...rest}>
      <Wrapper flush>
        {heading?.text && (
          <H1 pl={[3, 4, 0]} pb={[4, 5]}>
            {heading.text}
          </H1>
        )}
        <ColorMode mode="dark">
          <Flex flexWrap="wrap">
            <Flex
              position="relative"
              flexDirection="column"
              order={align === "Right" ? [-1, null, 1] : -1}
              width={["100%", null, 1 / 3]}
              bg={bg}
            >
              {/* Text box */}
              <Flex flex={1} alignItems="center" py={[5, null, overlapAmount]}>
                <Wrapper px={[3, 4, 5, 5]}>
                  {text && <RichText content={text} />}
                </Wrapper>
              </Flex>
              {/* Pseudo-overlap box */}
              <Box
                css={css({
                  position: "absolute",
                  top: 0,
                  right: align === "Left" && 0,
                  left: align === "Right" && 0,
                  display: ["none", null, "block"],
                  width: overlapAmount,
                  height: "100%",
                  bg: bg,
                  transform:
                    align === "Right"
                      ? "translateX(-100%)"
                      : "translateX(100%)",
                })}
              />
            </Flex>
            {/* Image */}
            {image && (
              <Flex
                position="relative"
                zIndex={1}
                flexDirection="column"
                justifyContent="center"
                width={["100%", null, 2 / 3]}
              >
                <Box py={[0, null, overlapAmount]}>
                  <Image src={image} alt={alt && alt} />
                </Box>
              </Flex>
            )}
          </Flex>
        </ColorMode>
      </Wrapper>
    </Box>
  )
}

export default Split
