import React from "react"
import Img from "gatsby-image/withIEPolyfill"

const Image = ({ alt, src, ...rest }) => {
  if (src && src?.fluid) {
    return <Img fluid={src.fluid} alt={alt} {...rest} />
  } else {
    if (src?.url) {
      return <img src={src} alt={alt} {...rest} />
    } else {
      return null
    }
  }
}

export default Image
