import styled from "@emotion/styled"
import shouldForwardProp from "@styled-system/should-forward-prop"
import { color, compose, space, textStyle, typography } from "styled-system"

const Heading = styled("h1", {
  shouldForwardProp,
})(
  {
    margin: 0,
  },
  compose(
    color,
    space,
    textStyle,
    typography
  )
)

Heading.defaultProps = {
  fontFamily: "heading",
  fontWeight: "heading",
  lineHeight: "heading",
  letterSpacing: "heading",
  color: "text",
}

export const H1 = styled(Heading)``
H1.defaultProps = {
  ...Heading.defaultProps,
  as: "h2",
  fontSize: [5, 6],
}

export const H2 = styled(Heading)``
H2.defaultProps = {
  ...Heading.defaultProps,
  as: "h2",
  fontSize: [4, 5],
}

export const H3 = styled(Heading)``
H3.defaultProps = {
  ...Heading.defaultProps,
  as: "h3",
  fontSize: [3, 4],
  fontWeight: "bold",
}

export const H4 = styled(Heading)``
H4.defaultProps = {
  ...Heading.defaultProps,
  as: "h4",
  fontSize: [2, 3],
  fontWeight: "bold",
}

export const H5 = styled(Heading)``
H5.defaultProps = {
  ...Heading.defaultProps,
  as: "h5",
  fontSize: [1, 2],
  fontWeight: "bold",
}

export const H6 = styled(Heading)``
H6.defaultProps = {
  ...Heading.defaultProps,
  as: "h6",
  fontSize: 0,
  fontWeight: 700,
  textStyle: "caps",
}
