import React from "react"
import css from "@styled-system/css"
import { rgba, triangle } from "polished"

import Box from "../box"

const SimpleSelect = ({ children, label, ...rest }) => (
  <Box
    css={css({
      bg: theme => rgba(theme.colors.text, 0.1),
    })}
  >
    <div
      css={css({
        position: "relative",
        display: "inline-block",
        width: "100%",
      })}
    >
      <div
        css={css({
          position: "absolute",
          top: "50%",
          right: "16px",
          color: "text",
          ...triangle({
            pointingDirection: "bottom",
            width: "10px",
            height: "5px",
            foregroundColor: "currentColor",
          }),
          transform: "translateY(-50%)",
          pointerEvents: "none",
          opacity: 0.5,
        })}
      />
      <select
        css={css({
          display: "inline-block",
          width: "100%",
          m: 0,
          py: 2,
          pr: "40px",
          pl: "12px",
          border: 0,
          borderRadius: 0,
          outline: 0,
          fontFamily: "body",
          fontSize: "16px",
          lineHeight: "body",
          letterSpacing: "body",
          color: "text",
          bg: "transparent",
          cursor: "pointer",
          appearance: "none",
          ":focus": {
            outline: 0,
          },
          "::-ms-expand": {
            display: "none",
          },
          ":focus:-moz-focusring": {
            color: "transparent",
            textShadow: "0 0 0 #000",
          },
        })}
        {...rest}
      >
        {children}
      </select>
    </div>
  </Box>
)

export default SimpleSelect
